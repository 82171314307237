// Customizable Area Start
import React, { useState, useEffect } from "react";
import { CsvDialog } from "../Dialog/CsvDialog";
import CsvUploadBox from "../UploadBox/CsvUploadBox.web";
import FormHelperText from "@material-ui/core/FormHelperText";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useStyles } from "./ImportCsvUploadDialogStyles";
import { configJSON } from "../../../inventorymanagement/src/InventoryManagementController.web";
import { useRunEngine } from "../../../../../packages/blocks/utilities/src/hooks/useRunEngine";
import { useBlockHelpers } from "../../../../../packages/blocks/utilities/src/hooks/useBlockHelpers";
import { Message } from "../../../../framework/src/Message";
import { getName } from "../Messages/MessageEnum";
import MessageEnum from "../../../../framework/src/Messages/MessageEnum";
import { getStorageData } from "../../../../framework/src/Utilities";
import { LinearProgress } from "@material-ui/core";
import { mixPanelEventHit } from "../../../../../packages/components/src/Mixpanel";

enum PHASE {
  REVIEW,
  UPLOAD
}

type Props = {
  onSubmit: () => void;
  open: boolean;
  setOpen: (newState: boolean) => void;
  onSampleDownload: () => void;
  onFileSelect: (files: any) => void;
  error: string;
  selectedCsvFile: any;
  showHint?: boolean;
  isAI: boolean
};

const subscribedMessages = [
  MessageEnum.RestAPIResponceMessage,
  MessageEnum.SessionResponseMessage,
];

export const ImportCsvUploadDialog = ({
  onSubmit,
  open,
  setOpen,
  onSampleDownload,
  onFileSelect,
  selectedCsvFile,
  error,
  showHint = true,
  isAI,
}: Props) => {
  const classes = useStyles();
  const [internalError, setInternalError] = useState(error);
  const [confirmDisabled, setConfirmDisabled] = useState(false);
  const [phase, setPhase] = useState(PHASE.REVIEW);
  const [showProgress, setShowProgress] = useState(false);
  const [dataCSV, setDataCSV] = useState([['']]);
  const [fileName, setFileName] = useState("");
  
  const uploadCSVFileId = React.useRef("");
  const mixPanelInfo = React.useRef({buildCardId : 0, emailId: ""});

  const {
    setReceiveCallback,
    subscribe,
    unsubscribeFromMessage,
    sendNetworkRequest
  } = useRunEngine();

  const { extractNetworkResponse } = useBlockHelpers();

  const receive = (from: string, message: Message) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, responseJson } = extractNetworkResponse(message);
      if (apiRequestCallId === uploadCSVFileId.current) {
        if (responseJson.error) {
          setConfirmDisabled(true);
          setFileName("")
          setShowProgress(false)
          setInternalError(responseJson.error)
          mixPanelEventHit("Review CSV AI Failed",{ buildCardId: mixPanelInfo?.current.buildCardId, emailId: mixPanelInfo?.current.emailId })
          setPhase(PHASE.REVIEW)
        } else {
          setConfirmDisabled(false);
          setShowProgress(false)
          setDataCSV(responseJson || [['']])
          setFileName(selectedCsvFile.name)
        }
      }
    }
  };

  const uploadCSVFile = async () => {
    const headers = {
      token: await getStorageData('admintoken'),
    };

    const formData = new FormData();
    formData.append("file", selectedCsvFile);
    sendNetworkRequest(
      uploadCSVFileId,
      "POST",
      "bx_block_data_import_export/import_items/export_csv_with_ai",
      headers,
      formData,
      true
    );
  }

  useEffect(() => {
    setInternalError(error);
    setConfirmDisabled(!!error);
  }, [error]);

  useEffect(() => {
    getCurrentMixPanelSetup()
  }, []);

  const getCurrentMixPanelSetup = async () => {
    mixPanelInfo.current = JSON.parse(await getStorageData("mixPanelInfo"))
  }

  useEffect(() => {
    subscribedMessages.forEach((message) => subscribe(message));
    setReceiveCallback(receive)

    return () => {
      subscribedMessages.forEach((message) => unsubscribeFromMessage(message));
    };
  }, [selectedCsvFile])

  const clearForm = () => {
    if (isAI) {
      mixPanelEventHit("Cancel CSV AI",{ buildCardId: mixPanelInfo?.current.buildCardId, emailId: mixPanelInfo?.current.emailId })
      setPhase(PHASE.REVIEW)
      onFileSelect(undefined);
      setConfirmDisabled(false);
      setInternalError('');
      setOpen(false)
    } else {
      mixPanelEventHit("Cancel CSV",{ buildCardId: mixPanelInfo?.current.buildCardId, emailId: mixPanelInfo?.current.emailId })
      onFileSelect(undefined);
      setInternalError('');
      setConfirmDisabled(false);
    }
  };

  const handleOnSubmit = async () => {
    if (isAI) {
      if (phase === PHASE.REVIEW) {
        mixPanelEventHit("Review CSV AI",{ buildCardId: mixPanelInfo?.current.buildCardId, emailId: mixPanelInfo?.current.emailId })
        setPhase(PHASE.UPLOAD)
        setShowProgress(true)
        setConfirmDisabled(true);
        uploadCSVFile()
      } else {
        mixPanelEventHit("Download CSV AI",{ buildCardId: mixPanelInfo?.current.buildCardId, emailId: mixPanelInfo?.current.emailId })
        setPhase(PHASE.REVIEW)
        onFileSelect(undefined);
        setConfirmDisabled(false);
        setInternalError('');
        setOpen(false)
      }
    } else {
      mixPanelEventHit("Submit CSV",{ buildCardId: mixPanelInfo?.current.buildCardId, emailId: mixPanelInfo?.current.emailId })
      onSubmit();
      setOpen(false)
    }
  };

  const handleIgnore = () => {
    if (isAI) {
      if (phase === PHASE.REVIEW) return true
      else return false
    } else return false
  }

  const getOkayText = () => {
    if (isAI) {
      if (phase === PHASE.REVIEW) return "Review using AI"
      else return "Download CSV"
    } else return "Import"
  }

  const renderMainContent = () => {
    if (isAI) {
      if (phase === PHASE.REVIEW) {
        return (
          <div className={classes.uploadWrapper}>
            {selectedCsvFile ? (
              <div className={classes.selectedCsvFileContainer}>
                <div className={classes.selectedFile}>
                  <div className={classes.uploadWrapper1}>
                    <span className={classes.selectedFileName}>
                      {selectedCsvFile.name}
                    </span>
                    <span className={classes.selectedFileSize}>
                      {selectedCsvFile.size > 1024 * 1024
                        ? `${Math.ceil(selectedCsvFile.size / 1024 / 1024)}mb`
                        : `${Math.ceil(selectedCsvFile.size / 1024)}kb`}
                    </span>
                  </div>
                  <IconButton
                    aria-label="close"
                    className={classes.removeButton}
                    onClick={clearForm}
                  >
                    <CloseIcon className={classes.closeIcon} />
                  </IconButton>

                </div>
                <div className={classes.errorMsgDiv2}>

                  {internalError && (
                    <CsvUploadBox
                      dropContainerStyle={{ marginTop: "40px" }}
                      uploadIcon={require("./assets/add.svg")}
                      height="240px"
                      width="425px"
                      onTouched={() => { }}
                      sizeMsg="Max 20MB"
                      uploadMsg={
                        <p className={classes.uploadBoxMessage}>
                          {configJSON.toUpload}
                        </p>
                      }
                      onSelectFile={onFileSelect}
                      acceptedFormat=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    />

                  )}
                  <div className={classes.downloadCsvBtnWrapper}>
                    <button className={classes.actionBtn} onClick={onSampleDownload}>{configJSON.sampleCsvTemplate}</button>
                    <a 
                      onClick={() => {
                        mixPanelEventHit("Click on need help importing",{ buildCardId: mixPanelInfo?.current.buildCardId, emailId: mixPanelInfo?.current.emailId })
                      }}
                      target="_blank"
                      href="https://intercom.help/engineerai/en/articles/9345173-restaurant-troubleshooting-guide-csv-upload" className={classes.actionBtn}>{configJSON.needToImporting}</a>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <CsvUploadBox
                  dropContainerStyle={{ marginTop: "20px" }}
                  uploadIcon={require("./assets/add.svg")}
                  height="240px"
                  width="425px"
                  onTouched={() => { }}
                  sizeMsg="Max 20MB"
                  uploadMsg={
                    <p className={classes.uploadBoxMessage}>
                      {configJSON.toUpload}
                    </p>
                  }
                  onSelectFile={onFileSelect}
                  acceptedFormat=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />
                <div className={classes.downloadCsvBtnWrapper}>
                  <button className={classes.actionBtn} onClick={onSampleDownload}>{configJSON.sampleCsvTemplate}</button>
                  <a 
                      onClick={() => {
                        mixPanelEventHit("Click on need help importing",{ buildCardId: mixPanelInfo?.current.buildCardId, emailId: mixPanelInfo?.current.emailId })
                      }}
                      target="_blank" 
                      href="https://intercom.help/engineerai/en/articles/9345173-restaurant-troubleshooting-guide-csv-upload" className={classes.actionBtn}>{configJSON.needToImporting}</a>
                </div>
              </>
            )}
          </div>
        )
      } else {
        if (showProgress) {
          return (
            <div className={classes.uploadWrapper}>
              <div className={classes.loadingView}>
                <span className={classes.textTitleReview}>Reviewing your CSV using AI</span>
                <LinearProgress classes={{colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary}}/>
                <span className={classes.textDescriptionReview}>
                  Sit back and relax this takes a moment. Remember, you can always refine your plan later!
                </span>
              </div>
            </div>
          )
        } else {
          return (
            <div className={classes.uploadWrapper}>
              <div className={classes.selectedCsvFileContainer}>
                <div className={classes.selectedFile}>
                  <div className={classes.uploadWrapper1}>
                    <span className={classes.selectedFileName}>
                      {selectedCsvFile.name}
                    </span>
                    <span className={classes.selectedFileSize}>
                      {selectedCsvFile.size > 1024 * 1024
                        ? `${Math.ceil(selectedCsvFile.size / 1024 / 1024)}mb`
                        : `${Math.ceil(selectedCsvFile.size / 1024)}kb`}
                    </span>
                  </div>
                  <IconButton
                    aria-label="close"
                    className={classes.removeButton}
                    onClick={clearForm}
                  >
                    <CloseIcon className={classes.closeIcon} />
                  </IconButton>
                </div>
                <div className={classes.errorMsgDiv2}>
                  {internalError && (
                    <CsvUploadBox
                      dropContainerStyle={{ marginTop: "40px" }}
                      uploadIcon={require("./assets/add.svg")}
                      height="240px"
                      width="425px"
                      onTouched={() => { }}
                      sizeMsg="Max 20MB"
                      uploadMsg={
                        <p className={classes.uploadBoxMessage}>
                          {configJSON.toUpload}
                        </p>
                      }
                      onSelectFile={onFileSelect}
                      acceptedFormat=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    />
                  )}
                </div>
              </div>
            </div>
          )
        }
      }
    } else {
      return (
        <div className={classes.uploadWrapper}>
          {selectedCsvFile ? (
            <div className={classes.selectedCsvFileContainer}>
              <div className={classes.selectedFile}>
                <div className={classes.uploadWrapper1}>
                  <span className={classes.selectedFileName}>
                    {selectedCsvFile.name}
                  </span>
                  <span className={classes.selectedFileSize}>
                    {selectedCsvFile.size > 1024 * 1024
                      ? `${Math.ceil(selectedCsvFile.size / 1024 / 1024)}mb`
                      : `${Math.ceil(selectedCsvFile.size / 1024)}kb`}
                  </span>
                </div>
                <IconButton
                  aria-label="close"
                  className={classes.removeButton}
                  onClick={clearForm}
                >
                  <CloseIcon className={classes.closeIcon} />
                </IconButton>

              </div>
              <div className={classes.errorMsgDiv2}>

                {internalError && (
                  <CsvUploadBox
                    dropContainerStyle={{ marginTop: "40px" }}
                    uploadIcon={require("./assets/add.svg")}
                    height="240px"
                    width="425px"
                    onTouched={() => { }}
                    sizeMsg="Max 20MB"
                    uploadMsg={
                      <p className={classes.uploadBoxMessage}>
                        {configJSON.toUpload}
                      </p>
                    }
                    onSelectFile={onFileSelect}
                    acceptedFormat=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  />

                )}
                <div className={classes.downloadCsvBtnWrapper}>
                  <button className={classes.actionBtn} onClick={onSampleDownload}>{configJSON.sampleCsvTemplate}</button>
                  <a 
                      onClick={() => {
                        mixPanelEventHit("Click on need help importing",{ buildCardId: mixPanelInfo?.current.buildCardId, emailId: mixPanelInfo?.current.emailId })
                      }}
                      target="_blank" href="https://intercom.help/engineerai/en/articles/9345173-restaurant-troubleshooting-guide-csv-upload" className={classes.actionBtn}>{configJSON.needToImporting}</a>
                </div>
              </div>
            </div>
          ) : (
            <>
              <CsvUploadBox
                dropContainerStyle={{ marginTop: "20px" }}
                uploadIcon={require("./assets/add.svg")}
                height="240px"
                width="425px"
                onTouched={() => { }}
                sizeMsg="Max 20MB"
                uploadMsg={
                  <p className={classes.uploadBoxMessage}>
                    {configJSON.toUpload}
                  </p>
                }
                onSelectFile={onFileSelect}
                acceptedFormat=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
              <div className={classes.downloadCsvBtnWrapper}>
                <button className={classes.actionBtn} onClick={onSampleDownload}>{configJSON.sampleCsvTemplate}</button>
                <a 
                  onClick={() => {
                    mixPanelEventHit("Click on need help importing",{ buildCardId: mixPanelInfo?.current.buildCardId, emailId: mixPanelInfo?.current.emailId })
                  }}
                  target="_blank" href="https://intercom.help/engineerai/en/articles/9345173-restaurant-troubleshooting-guide-csv-upload" className={classes.actionBtn}>{configJSON.needToImporting}</a>
              </div>
            </>
          )}
        </div>
      )
    }
  }

  const getHeaderText = () => {
    if (isAI) {
      if (phase === PHASE.REVIEW) return configJSON.csvDialogMessageText
      else {
        if (showProgress) return configJSON.csvDialogReviewText
        else return configJSON.csvDialogDownloadedText
      }
    } else return configJSON.csvDialogMessageText
  }

  return (
    <CsvDialog
      onClose={clearForm}
      onSubmit={handleOnSubmit}
      open={open}
      setOpen={setOpen}
      title={configJSON.titleImportMenu}
      containerClassName={classes.dialog}
      customContent
      width="684px"
      minHeight="540px"
      confirmDisabled={confirmDisabled || !selectedCsvFile}
      okClassName="csvuploadImportBtn"
      okay={getOkayText()}
      ignoreClickAway={handleIgnore()}
      data={dataCSV}
      isAIButton={isAI && getOkayText() === "Download CSV"}
      hideButton={showProgress}
      hideCancel={isAI && getOkayText() === "Download CSV" && showProgress}
      fileName={fileName}
    >
      <div className={classes.content}>
        {showHint && (
          <div className={classes.hint}>
            <span className={classes.textHeader}>{getHeaderText()}</span>
          </div>
        )}
        {internalError && (
          <FormHelperText className={classes.errorMsgDiv} error={true}>
            {internalError}
          </FormHelperText>
        )}
        {renderMainContent()}
      </div>
    </CsvDialog>
  );
};
// Customizable Area End
