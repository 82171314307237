import imageCompression from "browser-image-compression";
import CryptoJS from "crypto-js";

export const uploadImageToS3 = async (file:any, url:string, checksum: string) => {
    try {
      const response = await fetch(url, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type,
          'Content-MD5': checksum,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to upload image');
      }
      return url.split('?')[0]; 
    } catch (error) {
      return null;
    }
    };

export const processImage = async (file:any, sizeArray : any) => {
      const sizes = sizeArray;
      const compressedImagesArray : any[] = [];
      
      // const originalImage = await createImageBitmap(file);
      // const aspectRatio = originalImage.width / originalImage.height;
      const optionOriginal = {
          useWebWorker: true,
          fileType: "image/webp",
          maxSizeMB: 0.2, // Max file size (1MB)
          maxIteration: 10, // Max number of iterations to compress the image
          initialQuality: 0.9,
          alwaysKeepResolution: true,
      }
      const compressedFileOriginal = await imageCompression(file, optionOriginal);
      const compressedSrcOriginal = URL.createObjectURL(compressedFileOriginal);
      (calculateMD5(compressedFileOriginal)).then((result)=> {
        compressedImagesArray.push({
          src: compressedSrcOriginal,
          compressedFile: compressedFileOriginal,
          filename: compressedFileOriginal.name,
          size: compressedFileOriginal.size,
          checksum: result,
          fileSize: "",
        });
      })
      for (const size of sizes) {
        const options = {
          maxWidthOrHeight: size,
          useWebWorker: true,
          fileType: "image/webp",
          maxSizeMB: 0.2, // Max file size (1MB)
          maxIteration: 10, // Max number of iterations to compress the image
          initialQuality: 0.9,
          alwaysKeepResolution: true, // Ensure the resolution is not reduced
        };
      
        const compressedFile = await imageCompression(file, options);
    
        const compressedSrc = URL.createObjectURL(compressedFile);
        const compressedFileSize = (compressedFile.size / 1024).toFixed(2); // File size in KB
        (calculateMD5(compressedFile)).then((result)=> {
          compressedImagesArray.push({
            src: compressedSrc,
            compressedFile: compressedFile,
            filename: compressedFile.name,
            size: compressedFile.size,
            checksum: result,
            fileSize: size,
          });
        })
      }
      return compressedImagesArray
    }

 const calculateMD5 = (file : any) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function () {
          const arrayBuffer = reader.result;
          const wordArray = CryptoJS.lib.WordArray.create(arrayBuffer);
          const md5Hash = CryptoJS.MD5(wordArray).toString(CryptoJS.enc.Base64);
          resolve(md5Hash);
        };
        reader.onerror = function (error) {
          reject(error);
        };
        reader.readAsArrayBuffer(file);
      });
    };

  export const convertBase64ToImageFile = (baseFile: any) => {
      const base64ImageData = baseFile;
      const [contentType, base64Data] = base64ImageData.split(';base64,');
      const mimeType = contentType.split(':')[1];
      const binaryData = atob(base64Data);
      const arrayBuffer = new ArrayBuffer(binaryData.length);
      const view = new Uint8Array(arrayBuffer);
      for (let i = 0; i < binaryData.length; i++) {
        view[i] = binaryData.charCodeAt(i);
      }
      const blob = new Blob([arrayBuffer], { type: mimeType });
      const file = new File([blob], "image.png", { type: mimeType });
      return file
  
    }