// Customizable Area Start
import React from "react";
import MuiDialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { CsvDialogStyle } from "../Dialog/CsvDialogStyle";
import { CSVLink } from "react-csv";

export type OpenState = {
  open: boolean;
  setOpen: (newState: boolean) => void;
};

export type DialogProps = {
  onClose?: () => void;
  onSubmit?: () => void;
  title?: string;
  message?: string | React.ReactNode;
  okay?: string;
  cancel?: string;
  okClassName?: string;
  cancelClassName?: string;
  titleClassName?: string;
  messageClassName?: string;
  containerClassName?: string;
  hideCancel?: boolean;
  isAIButton?: boolean
  hideButton?: boolean
  customContent?: boolean;
  minWidth?: string;
  minHeight?: string;
  width?: string | number;
  confirmDisabled?: boolean;
  confirmColor?: string;
  confirmBackground?: string;
  confirmHoverBackground?: string;
  ignoreClickAway?: boolean;
  children?: React.ReactNode;
  data: string[][]
  fileName: string
};

export const CsvDialog = withStyles(CsvDialogStyle)(
  ({
    open,
    setOpen,
    onClose,
    onSubmit,
    title = "Dialog Title",
    message = "Dialog message...",
    okay,
    cancel = "Cancel",
    okClassName = "",
    cancelClassName = "",
    titleClassName = "",
    messageClassName = "",
    containerClassName = "",
    hideCancel = false,
    isAIButton = false,
    hideButton = false,
    customContent = false,
    confirmDisabled = false,
    children,
    classes,
    ignoreClickAway,
    data,
    fileName,
  }: DialogProps & OpenState & { classes: any }) => {
    const handleDialogAction = (action: any) => async (e: any, reason: string) => {
      const res = await action?.();
      if (res !== false && !ignoreClickAway) setOpen(false);
    };

    const renderOkButton = () => {
      if (isAIButton) {
        if (hideButton) {
          return <div />
        } else {
          return (
            <CSVLink
              data={data}
              filename={`AI_reviewed_${fileName}`}
              asyncOnClick={true}
              onClick={() => {
                if (!confirmDisabled) {
                  onSubmit?.()
                }
              }}
              className={classes.confirmButton}
              style={{
                width: "unset",
                textDecoration: "none",
                padding: "20px 62px",
                backgroundColor: confirmDisabled ? "#E2E8F0" : "#00D659",
                color: confirmDisabled ? "#64748B" : "#0F172A",
                fontWeight: 500,
              }}
            >
              Download CSV
            </CSVLink>
          )
        }
      } else {
        return (
          // @ts-ignore
          <Button
            data-testid="dialog-button-confirm"
            className={okClassName}
            classes={{
              root: classes.confirmButton,
            }}
            onClick={handleDialogAction(onSubmit)}
            autoFocus
            disabled={confirmDisabled}
            style={{
              width: "unset",
              textDecoration: "none",
              padding: "20px 62px",
              fontWeight: 500,
            }}
          >
            {okay}
          </Button>
        )
      }
    }

    return (
      <MuiDialog
        open={open}
        onClose={handleDialogAction(onClose)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={containerClassName}
        classes={{
          paper: classes.dialogPaper,
        }}
      >
        <DialogTitle
          disableTypography
          id="alert-dialog-title"
          className={titleClassName}
          classes={{
            root: classes.dialogTitle,
          }}
        >
          {title}
          {/* @ts-ignore */}
          <IconButton
            aria-label="close"
            onClick={handleDialogAction(onClose)}
            classes={{
              root: classes.closeButton,
            }}
          >
            <i className="icon-cross" />
          </IconButton>
        </DialogTitle>
        {!customContent && (
          <DialogContent
            classes={{
              root: classes.messageContainer,
            }}
          >
            <DialogContentText
              className={messageClassName}
              classes={{
                root: classes.message,
              }}
            >
              {message}
            </DialogContentText>
          </DialogContent>
        )}
        {children}
        <DialogActions
          classes={{
            root: classes.actionsRoot,
          }}
        >
          {!hideCancel && (
            // @ts-ignore
            <Button
              data-testid="dialog-button-cancel"
              className={cancelClassName}
              classes={{
                root: classes.cancelButton,
              }}
              onClick={handleDialogAction(onClose)}
            >
              {cancel}
            </Button>
          )}
          {renderOkButton()}
        </DialogActions>
      </MuiDialog>
    );
  }
);
// Customizable Area End
