import React, { Component } from 'react';

// Customizable Area Start
import {
    Grid,
    Box,
    Theme,
    Typography,
    Button,
    withStyles,
    WithStyles,
  } from "@material-ui/core";
  import { noProducts } from "./assets";
// Customizable Area End


// Customizable Area Start
const configJSON = require("./config");
interface Props extends WithStyles {
  addItem: () => void;
  onAddFromLibraryClick: () => void;
  onImportMenu: (isAI: boolean) => void;
  showAddItemAI: boolean
}
// Customizable Area End
export class NoItems extends Component<Props> {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes, addItem, onAddFromLibraryClick, onImportMenu } = this.props;
        return (
        <Grid container className={classes.MainGrid}>
            <Grid item className={classes.MainGridItem}>
            <Box className={classes.IconBox}>
                <img
                src={noProducts}
                alt={configJSON.noItemsTitle}
                className={classes.NoItemIcon}
                />
            </Box>
            <Typography className={classes.TitleText}>
                {configJSON.noItemsTitle}
            </Typography>
            <Box className={classes.ActionButtonBox}>
                <Button 
                    data-test-id="import_csv"
                    variant="outlined"
                    className={classes.ActionButton}
                    onClick={() => onImportMenu(false)}
                >
                    <div className={classes.ActionButtonSection}>
                        <Typography className="ActionButtonText">
                            {configJSON.importMenu}
                        </Typography>
                        <span className={classes.ActionButtonMsg}>
                            {configJSON.importMenuMsg}
                        </span>
                    </div>
                </Button>
                <Button variant="outlined" className={classes.ActionButton} onClick={onAddFromLibraryClick}>
                    <div className={classes.ActionButtonSection}>
                        <Typography className="ActionButtonText">
                            {configJSON.builderLibrary}
                        </Typography>
                        <span className={classes.ActionButtonMsg}>
                            {configJSON.builderLibraryMsg}
                        </span>
                    </div>
                </Button>
                <Button
                    variant="outlined"
                    className={classes.ActionButton}
                    onClick={addItem}
                >
                    <div className={classes.ActionButtonSection}>
                        <Typography className="ActionButtonText">
                            {configJSON.addItem}
                        </Typography>
                        <span className={classes.ActionButtonMsg}>
                            {configJSON.addItemMsg}
                        </span>
                    </div>
                </Button>
                {
                    this.props.showAddItemAI && (
                        <Button
                            variant="outlined"
                            className={classes.ActionButton}
                            onClick={() => onImportMenu(true)}
                        >
                            <div className={classes.ActionButtonSection}>
                                <Typography className="ActionButtonText">
                                    {configJSON.addItemAI}
                                </Typography>
                                <span className={classes.ActionButtonMsg}>
                                    {configJSON.addItemAIMsg}
                                </span>
                            </div>
                        </Button>
                    )
                }
            </Box>
            </Grid>
        </Grid>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export const NoItemsStyle = (theme: Theme) => ({
    MainGrid: {
        alignItems: "center" as const,
        display: "flex",
        justifyContent: "center",
    },
    MainGridItem: {
        backgroundColor: "#FFFFFF",
        width: 772,
        padding: "84px 48px 48px 48px",
        borderRadius: 10,
        marginTop: "5%",
        [theme.breakpoints.down("xs")]: {
            padding: "48px 16px",
        },
    },
    IconBox: {
        display: "flex",
        justifyContent: "center",
        marginBottom: 16,
    },
    NoItemIcon: {
        height: 76,
        width: 76,
        objectFit: "contain" as const,
    },
    TitleText: {
        fontFamily: "Inter",
        fontSize: 24,
        fontWeight: 700,
        textAlign: "center" as const,
        lineHeight: 1.5,
        color: "#363636",
        margin: "0px 8px 16px 0px",
    },
    ActionButtonBox: {
        display: "flex",
        justifyContent: "center",
        gap: "10px",
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
            alignItems: "center" as const,
        },
    },
    ActionButton: {
        padding: "10px 26px 10px 20px",
        border: "1px solid #CBD5E1",
        borderRadius: 3,
        width:226,
        "& .ActionButtonText": {
            fontFamily: "Rubik",
            fontSize: 16,
            fontWeight: 500,
            textAlign: "start" as const,
            lineHeight: 1.4,
            color: "#3C3E49",
            textTransform: "initial" as const,
            whiteSpace: "nowrap",
            paddingBottom:"3px",
        },
    },
    ActionButtonSection:{
        display:"flex",
        flexDirection:"column" as const,
        textAlign:"start" as const,
    },
    ActionButtonMsg:{
        color:"#475569",
        fontSize:"12px",
        fontWeight:400,
        textTransform:"none" as const
    },
})
export default withStyles(NoItemsStyle)(NoItems);
// Customizable Area End