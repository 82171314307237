// Customizable Area Start
import { Box, Checkbox, TableCell, TableRow, TextField } from '@material-ui/core';
import { Autocomplete, createFilterOptions, FilterOptionsState } from '@material-ui/lab';
import { ILibraryItem } from '../../../packages/blocks/inventorymanagement/src/ItemsLibraryController.web';
import React from 'react';

type Props = {
    index: number
    category: ILibraryItem;
    classes: Record<string, string>;
    allChecked: boolean
    handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>, id: number) => void
    handleUpdateCategoriesData: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, catergoryId: number, param: string) => void
    onChangeSelectCategoryInput: (value: string, catergoryId: number) => void
}

let filter = createFilterOptions<string>();
const getFilterItems = (optionsData: string[], paramsData: FilterOptionsState<string>) => {
    const filtered = filter(optionsData, paramsData);

    if (paramsData.inputValue !== "") {
        if (!optionsData.includes(paramsData.inputValue)) {
            filtered.push(paramsData.inputValue);
        }
    }

    return filtered;
}

export const ItemBuilderLibrary = ({
    index,
    category,
    classes,
    allChecked,
    handleCheckboxChange,
    handleUpdateCategoriesData,
    onChangeSelectCategoryInput
}: Props) => {
    const [selectedCategory, setSelectedCategory] = React.useState(category.selectedCategory)
    const [searchText, setSearchText] = React.useState(category.categories[0])
    const [categoryList, setCategoryList] = React.useState(category.categories)
    const [checked, setChecked] = React.useState(Boolean(category.checked))
    const [allCheckedBox, setAllChecked] = React.useState(allChecked)
    const [name, setName] = React.useState(category.name)
    const [description, setDescription] = React.useState(category.description)
    const [price, setPrice] = React.useState(category.selected_price)

    React.useEffect(() => {
        setChecked(category.checked)
    }, [allChecked])

    const handleUpdateCategoriesSelectedData = (
        categoryVal: string | null,
        param: string,
    ) => {
        if (typeof categoryVal === 'string') {
            if (param === 'selectedCategory') {
                if(!categoryList.includes(categoryVal)){
                    let newUpdatedCat = [...categoryList, categoryVal];
                    setCategoryList(newUpdatedCat)
                }
                const syntdeticEvent = {
                    target: {
                        value: categoryVal
                    }
                };
                handleUpdateCategoriesData(syntdeticEvent as React.ChangeEvent<HTMLInputElement>, category.id, "selectedCategory");
                setSelectedCategory(categoryVal)
            } else if (param === 'searchedText') {
                setSearchText(categoryVal)
                onChangeSelectCategoryInput(categoryVal, category.id)
            }
        }
    };

    const selectMenuItemsCategories = () => {
        return (
            <Autocomplete
                data-testId="autocomplete"
                className={classes.tableInput}
                id="autocomplete"
                options={categoryList}
                value={selectedCategory}
                inputValue={searchText}
                onInputChange={(event, inputVal) => {
                    handleUpdateCategoriesSelectedData(inputVal, "searchedText")
                }}
                onChange={(event, inputVal) => {
                    handleUpdateCategoriesSelectedData(inputVal, "selectedCategory")
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        data-testId="category-categories"
                        placeholder="Select"
                        variant="outlined"
                        id="textfield"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: null,
                            style: { padding: "0px 20px 0px 10px" },
                        }}
                    />
                )}
                filterOptions={getFilterItems}
                renderOption={(options) => {
                    if (!categoryList.includes(options)) {
                        return (
                            <span className={classes.addCategoryInput}>
                                {`+ Add <${searchText}>`}
                            </span>
                        )
                    } else {
                        return options
                    }
                }}
            />
        );
    };

    const handlePriceInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { value } = event.target;
        const numericValue = value.replace(/[^\d.]/g, '');
        const syntdeticEvent = {
            target: {
                value: numericValue
            }
        };
        setPrice(numericValue)
        handleUpdateCategoriesData(syntdeticEvent as React.ChangeEvent<HTMLInputElement>, category.id, "selected_price");
    };

    return (
        <>
            <TableCell
                component="td"
                scope="row"
                className={classes.dataText}
            >
                <Checkbox
                    className={classes.checkAllCheckbox}
                    checked={checked}
                    data-testId="checkbox"
                    onChange={(event) => {
                        setChecked(event.target.checked)
                        handleCheckboxChange(event, category.id)
                    }}
                />
            </TableCell>
            <TableCell
                component="td"
                scope="row"
                className={`${classes.dataText} itemName`}
            >
                <TextField
                    variant="outlined"
                    multiline
                    inputProps={{ maxLengtd: 50 }}
                    data-testId="category-name"
                    className={classes.tableInput}
                    value={name}
                    onChange={(event) => {
                        setName(event.target.value)
                        handleUpdateCategoriesData(event, category.id, "name")
                    }}
                    error={checked && name.trim() === ""}
                    helperText={checked && name.trim() === "" ? "Item name is required" : ""}
                />
            </TableCell>
            <TableCell
                component="td"
                scope="row"
                className={classes.dataText}
            >
                <Box className="ImageBox">
                    <img src={category.image_url} className="itemImage" />
                </Box>
            </TableCell>
            <TableCell
                component="td"
                scope="row"
                className={`${classes.dataText} description`}
            >
                <TextField
                    variant="outlined"
                    multiline
                    inputProps={{ maxLengtd: 300 }}
                    className={`${classes.tableInput} description`}
                    value={description}
                    data-testId="category-description"
                    onChange={(event) => {
                        setDescription(event.target.value)
                        handleUpdateCategoriesData(event, category.id, "description")
                    }}
                    error={checked && (description === null || description?.trim() === "")}
                    helperText={checked && (description === null || description?.trim() === "") ? "Description is required" : ""}
                />
            </TableCell>
            <TableCell
                component="td"
                scope="row"
                className={classes.dataText}
            >
                <TextField
                    variant="outlined"
                    className={classes.tableInputPrice}
                    value={price}
                    data-testId="category-price"
                    onChange={(event) => handlePriceInputChange(event)}
                    error={checked && price == 0}
                    helperText={checked && price == 0 ? "Price is required" : ""}
                />
            </TableCell>
            <TableCell
                component="td"
                scope="row"
                className={`${classes.dataText} category`}
            >
                {selectMenuItemsCategories()}
            </TableCell>
        </>
    )
};

// Customizable Area End
