// Customizable Area Start
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  dialog: {},
  content: {
    padding: "0 40px 40px 40px",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  colorPrimary: {
    backgroundColor: '#00d659',
    width: "calc(100% - 140px)"
  },
  barColorPrimary: {
    backgroundColor: '#f1f5f9',
  },
  loadingView: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    flexDirection: "column",
    gap: "22px",
    justifyContent: "center"
  },
  textDescriptionReview: {
    fontSize: "14px",
    lineHeight: "19.6px",
    fontWeight: 400,
    fontFamily: "Rubik",
    color: "#000",
    margin: "0 40px",
    textAlign: "center"
  },
  textTitleReview: {
    fontSize: "18px",
    lineHeight: "26px",
    fontWeight: 700,
    fontFamily: "Inter",
    color: "#3C3E49"
  },
  hint: {
    display: "flex",
    gap: "1ch",
    "& span": {
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "24px",
      display: "flex",
      alignItems: "center",
      color: "#3c3e49",
    },
    "& button": {
      all: "unset",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "24px",
      display: "flex",
      alignItems: "center",
      color: "#6200ea",
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
  uploadWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "20px",
    flex: 1
  },
  uploadWrapper1: {
    display: "flex",
    flexDirection: "column",
  },
  uploadBoxMessage: {
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "28px",
    letterSpacing: "-0.02em",
    color: "#676b7e",
    width: "176px",
  },
  textHeader: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "24px",
    color: "#3C3E49",
  },
  selectedCsvFileContainer: {
    width: "100%",
    height: "100%",
    flex: 1,
    display: 'flex',
    flexDirection: "column",
    justifyContent: "space-between"
  },
  selectedFile: {
    position: "relative",
    display: "flex",
    gap: "16px",
    backgroundColor: "#F6F0FF",
    padding: "12px",
    justifyContent: "space-between",
  },
  selectedFileIcon: {
    fontSize: "55px",
  },
  selectedFileName: {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#334155",
  },
  selectedFileSize: {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    color: "#475569",
  },
  removeButton: {
    display: "flex",
    position: "absolute",
    padding: "0",
    top: "0",
    right: "0",
    margin: "24px 10px 0 0",
    width: "18px",
    height: "18px",
  },
  downloadCsvBtnWrapper:{
    width: '425px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  actionBtn:{
    all: "unset",
    fontFamily: 'var(--website-builder-body-font)',
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "24px",
    display: "flex",
    alignItems: "center",
    color: "#6200ea",
    textDecoration: "underline",
    cursor: "pointer",
  },
  errorMsgDiv:{
    display:"flex",
    alignItems:"center",
    width: "100%",
    fontFamily:"Inter",
    height:"42px",
    lineHeight:"18px",
    fontSize:"12px",
    fontWeight:400,
    background:"#FEE2E2",
    padding:"8px 5px",
    marginTop:"20px",
    borderRadius:"4px",
    borderLeft: "4px solid #DC2626",
  },
  closeIcon:{ 
    width: "18px",
    height: "18px",
    color: "#000000"
   },
   errorMsgDiv2:{
    width:"100%",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    flexDirection:"column"
   }
});
// Customizable Area End
