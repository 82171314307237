//@ts-nocheck
// Customizable Area Start
import React, { createRef } from "react";
// Customizable Area Start
import {
  Box,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Switch,
  FormGroup,
  FormControlLabel,
  Select,
  Menu,
  MenuItem,
  CardContent,
  Card,
  Checkbox,
  Chip,
  FormHelperText,
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Modal,
  styled,
  Tooltip
} from "@material-ui/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import withStyles, { CSSProperties } from "@material-ui/core/styles/withStyles";
import SectionLoader from "../../../components/src/SectionLoader.web";
import InventoryManagementController, {
  Props,
  configJSON
} from "./InventoryManagementController.web";
import {
  down_arrow,
  close,
  chevron_down,
  chevron_up,
  crossIcon,
  backIcon,
  noProducts,
  noCategories,
  editIcon,
  veg_icon,
  nonVeg_icon,
  vegan_icon,
  glutenfree_icon,
  halal_icon,
  kosher_icon,
  eggs_icon,
  nuts_icon,
  wheat_icon,
  soy_icon,
  peanuts_icon,
  celery_icon,
  fish_icon,
  crustaceans_icon,
  lupin_icon,
  mollusc_icon,
  mustard_icon,
  sesameseeds_icon,
  sulphurdioxide_icon,
  milk_icon,
  mild_icon,
  spicy_icon,
  extraspicy_icon,
  searchIcon,
  Veg_icon,
  Eggs_icon,
  Celery_icon,
  Crustaceans_icon,
  Extraspicy_icon,
  Fish_icon,
  Glutenfree_icon,
  Halal_icon,
  Kosher_icon,
  Lupin_icon,
  Mild_icon,
  Milk_icon,
  Mollusc_icon,
  Mustard_icon,
  NonVeg_icon,
  Nuts_icon,
  Peanuts_icon,
  Sesameseeds_icon,
  Soy_icon,
  Spicy_icon,
  Sulphurdioxide_icon,
  Vegan_icon,
  Wheat_icon,
  CloseIconPng,
  successTick,
  warning,
  failed,
  deleteItemsIcon
} from "./assets";
import { Formik } from "formik";
import * as Yup from "yup";
import AlertMessage from "../../../components/src/AlertMessage.web";
import SubmitButtonPopup from "../../../components/src/SubmitButtonPopup.web";
import AddEditVariant from "./AddNewVariant.web";
import AddVariantSearch from "./AddNewVariantSearch.web";
import withLayout from "../../ss-cms-common-components/src/withLayout/withLayout";
import { Scrollbars } from "react-custom-scrollbars";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import CloseIcon from '@material-ui/icons/Close';
import Pagination from "../../ss-cms-common-components/src/Pagination/Pagination";
import NoItems from "./NoItems.web";
import { ImportCsvUploadDialog } from "../../ss-cms-common-components/src/CsvUploadDialog/ImportCsvUploadDialog.web";
import { mixPanelEventHit } from "../../../components/src/Mixpanel";
import { Prompt } from 'react-router'

const PurpleCheckbox = withStyles({
  root: {
    "&$checked": {
      color: "#6200EA",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export const foodLabelCheck = (item: any, background: boolean = true) => {
  switch (item) {
    case "Vegetarian":
      return background ? veg_icon : Veg_icon;
    case "Non-Vegetarian":
      return background ? nonVeg_icon : NonVeg_icon;
    case "Vegan":
      return background ? vegan_icon : Vegan_icon;
    case "Gluten free":
      return background ? glutenfree_icon : Glutenfree_icon;
    case "Halal":
      return background ? halal_icon : Halal_icon;
    case "Kosher":
      return background ? kosher_icon : Kosher_icon;
    case "Milk":
      return background ? milk_icon : Milk_icon;
    case "Eggs":
      return background ? eggs_icon : Eggs_icon;
    case "Nuts":
      return background ? nuts_icon : Nuts_icon;
    case "Wheat":
      return background ? wheat_icon : Wheat_icon;
    case "Soy":
      return background ? soy_icon : Soy_icon;
    case "Peanuts":
      return background ? peanuts_icon : Peanuts_icon;
    case "Celery":
      return background ? celery_icon : Celery_icon;
    case "Fish":
      return background ? fish_icon : Fish_icon;
    case "Crustaceans":
      return background ? crustaceans_icon : Crustaceans_icon;
    case "Lupin":
      return background ? lupin_icon : Lupin_icon;
    case "Mollusc":
      return background ? mollusc_icon : Mollusc_icon;
    case "Mustard":
      return background ? mustard_icon : Mustard_icon;
    case "Sesame Seeds":
      return background ? sesameseeds_icon : Sesameseeds_icon;
    case "Sulphur Dioxide":
      return background ? sulphurdioxide_icon : Sulphurdioxide_icon;
    case "Mild":
      return background ? mild_icon : Mild_icon;
    case "Spicy":
      return background ? spicy_icon : Spicy_icon;
    case "Extra Hot":
      return background ? extraspicy_icon : Extraspicy_icon;
    default:
      return background ? veg_icon : Veg_icon;
  }
};

export const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 48,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 2,
    "&$checked": {
      transform: "translateX(20px)",
      color: "#fff",
      "& + $track": {
        backgroundColor: "#35B459",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#red",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 23,
    height: 22,
  },
  track: {
    borderRadius: 26 / 2,
    border: "none",
    backgroundColor: "#C0C3CE",
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const validationSchema = Yup.object().shape({
  product_title: Yup.string()
    .max(36, "Must be less than 36 characters")
    .required("Title is required"),
  product_desc: Yup.string()
    .required("Description is required")
    .max(300, "Must be less than 300 characters"),
  product_category: Yup.array().min(1).required(),
  product_stock: Yup.string().required(),
  product_price: Yup.number().positive("price must be more than 0")
    .typeError("Only numbers are allowed")
    .required("Price is required"),
  product_discountValue: Yup.number().positive()
    .typeError("Only numbers are allowed")
    .min(0, "Discount must be 0 to 100")
    .max(100, "Discount must be 0 to 100"),
  packageHeight: Yup.number().positive().required(configJSON.itemValidations.heightRequired).typeError(configJSON.itemValidations.onlyNumberAllowed).min(0, configJSON.itemValidations.greaterThanZero),
  packageWidth: Yup.number().positive().required(configJSON.itemValidations.widthRequired).typeError(configJSON.itemValidations.onlyNumberAllowed).min(0, configJSON.itemValidations.greaterThanZero),
  packageBreadth: Yup.number().positive().required(configJSON.itemValidations.breadthRequired).typeError(configJSON.itemValidations.onlyNumberAllowed).min(0, configJSON.itemValidations.greaterThanZero),
  packageWeight: Yup.number().positive().required(configJSON.itemValidations.weightRequired).typeError(configJSON.itemValidations.onlyNumberAllowed).min(0, configJSON.itemValidations.greaterThanZero)
});

// Customizable Area End
export class InventoryManagement extends InventoryManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.imgRef = createRef();
    this.submitRef = createRef();
    this.formikRef = createRef()
    // Customizable Area End
  }

  // Customizable Area Start
  foodLabelBlock = (data: any, classes) => {
    return (
      <Grid style={{ display: "flex" }}>
        {data.attributes.food_lables.length > 0 ? (
          data.attributes.food_lables?.map((item: any, index_num: any) => {
            if (index_num < 3) {
              return (
                <Grid className={classes.foodLables}>
                  <img
                    src={foodLabelCheck(item)}
                    alt="veg"
                    style={{ height: "28px", width: "28px" }}
                  />
                </Grid>
              );
            }
          })
        ) : (
          <span className={classes.dataText}>N/A</span>
        )}

        {data.attributes.food_lables.length > 3 && (
          <Grid className={classes.foodLablesCount}>
            <Typography
              style={{
                fontFamily: "Inter",
                fontSize: "12px",
                fontWeight: "500",
                lineHeight: "15px",
                color: "#64748B",
              }}
            >
              +{data.attributes.food_lables.length - 3}
            </Typography>
          </Grid>
        )}
      </Grid>
    );
  };
  discountCheck = (data) => {
    return data.attributes.discount > 0
      ? this.currencyType + data.attributes.after_discount
      : "N/A";
  };

  renderToggleIcon = (isMenuOpen) => {
    return isMenuOpen ? 
    <ExpandLessIcon style={{marginLeft: "8px"}}/> : 
    <ExpandMoreIcon style={{marginLeft: "8px"}}/>;
  };

  renderCheckbox = (data) => {
    const { classes } = this.props;
    return (
      <Checkbox
        className={classes.checkAllCheckbox}
        checked={data.checked || false}
        data-testId="checkBox"
        onChange={(event) => this.handleCheckboxChange(event, data.id)}
      />
    );
  };

  getAvailabilityText  = (data) => {
    return (
      data.attributes.availability == "in_stock"
      ? "Available"
      : "Not available"
    );
  };

  getAvailabilityColor = (data) => {
    return (
      data.attributes.availability == "in_stock"
      ? "#059669"
      : "#DC2626"
    );
  };

  handleViewAvailability(availability: string) {
    if (availability == "in_stock") {
      return {
        color: "#059669",
        title: "Available",
      };
    } else {
      return {
        color: "#DC2626",
        title: "Not available",
      };
    }
  }

  searchFilterFoodLable = (classes) => {
    return (
      <>
        <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <Grid style={{ width: '15%', height: "41px", textAlign: 'center', background: "#FAFAFA", borderRadius: "3px 0px 0px 3px", border: '1px solid #D0D2DA', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Grid data-testId="foodlable_filter" onClick={this.handleDialogOpen} style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: 700,
              padding: "0px 10px",
              lineHeight: "22px", justifyContent: "center", cursor: "pointer"
            }}>
              {configJSON.filterItems}
              <img src={down_arrow} alt="downarrow" style={{ paddingLeft: "8px" }} />
            </Grid>
          </Grid>
          <Grid style={{ width: '85%', display: 'flex', alignItems: 'center' }}>
            <Autocomplete
              name="product_search"
              data-testId="searchproductInput"
              options={this.state.productSearchData}
              className={classes.InputStyle}
              inputValue={this.state.InputValue}
              onInputChange={(event, inputVal) => {
                this.setState({ InputValue: inputVal });
                this.searchProductWithText(inputVal);
              }}
              size="small"
              getOptionLabel={(option) => option.name}
              renderOption={(option) => {
                return (
                  <Grid style={{ display: "flex", alignItems: "center" }}>
                    {option?.images?.url && (
                      <Grid style={{ marginRight: "5px" }}>
                        <img
                          src={option?.images?.url}
                          alt="icon"
                          style={{
                            width: "24px",
                            height: "24px",
                            marginRight: "10px",
                          }}
                        />
                      </Grid>
                    )}
                    <Grid className={classes.menuItemNameSearch}>
                      {option.name}
                    </Grid>
                  </Grid>
                );
              }}
              // disablePortal={true}
              ListboxProps={{
                style: {
                  maxHeight: "150px",
                  margin: "10px",
                },
              }}
              freeSolo
              renderInput={(params) => (
                <TextField
                  {...params}
                  className={classes.placeholderText}
                  placeholder="Search Menu"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    onKeyDown: (event) => {
                      if (event.key === "Enter") {
                        event.stopPropagation();
                      }
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          src={searchIcon}
                          alt="searchIcon"
                          style={{ marginLeft: "15px" }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
        {
          this.state.appliedFoodLables?.length > 0 &&
          <Grid style={{ marginTop: "20px", display: "flex", alignItems: "center" }}>
            <Typography variant="h6" style={{
              fontFamily: "Inter",
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "18px",
              color: "#5500CC"
            }}>Filter applied:</Typography>
            <Grid>
              {this.state.appliedFoodLables.map((foodLabel: any) => {
                return (
                  <Chip
                    key={foodLabel}
                    label={foodLabel}
                    data-testId="handleFoodLabelsFilterDelete"
                    deleteIcon={<CloseIcon style={{ color: "#5500CC", height: "16px", width: "16px" }} />}
                    onDelete={() => { this.handleFoodLabelsFilterDelete(foodLabel) }}
                    style={{
                      marginLeft: "5px",
                      marginBottom: "5px",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: 700,
                      lineHeight: "18px",
                      color: "#5500CC",
                      background: "#F6F0FF",
                      padding: "4px 5px"
                    }}
                  />
                )
              })}
            </Grid>
          </Grid>
        }
      </>
    )
  }

  renderProduct(classes) {
    const {menuAnchorEl } = this.state;
    const isMenuOpen = Boolean(menuAnchorEl);
    return (
      <Grid item style={{ marginTop: "40px" }}>
        <Grid
          item
          lg={12}
          md={12}
          xs={12}
          className={classes.menuHeader}
        >
          <Grid item lg={1} md={1}>
            <Typography
              variant="h5"
              style={{
                fontFamily: "Rubik",
                fontSize: "26px",
                fontWeight: "500",
                lineHeight: "31px",
                color:"#3C3E49"
              }}
            >
              Menu
            </Typography>
          </Grid>
          
          {this.state.importInProgress ? ( 
            <>
              <Grid item lg={7} md={7}>
                <>
                  <div style={{ display: 'flex', gap: '10px', alignItems: 'baseline' }}>
                    <div class="progress" style={{ height: '8px', backgroundColor: '#E2E8F0', borderRadius: '15px', width: '433px' }}>
                      <div style={{ height: '8px', backgroundColor: '#00D659', borderRadius: '15px', width: `${this.state.progress}%` }}></div>
                    </div>
                    <div> <span style={{ fontFamily: 'Rubik', fontWeight: 400, fontSize: '12px', lineHeight: '20px', color: '#3C3E49' }}>{this.state.progress}% completed</span></div>
                  </div>

                  <div>
                    <span style={{ fontFamily: 'Rubik', fontWeight: 400, fontSize: '12px', lineHeight: '20px', color: '#6200EA' }}>
                      Sit back while we create your menu ...
                    </span>
                  </div>
                </>
              </Grid>
              <Grid item lg={4} md={4}>
            <Box className={classes.MenuActionBox}>
              <Button
                variant="contained"
                className={classes.addMenuItemButtonDisable}
                style={{ background: "#00D659", backgroundColor: "#E2E8F0" }}
                data-testId="addMenuItemDisable"
                onClick={this.handleMenuClick}
              >
                {configJSON.addItem}
                <ExpandMoreIcon style={{marginLeft: "8px"}}/> 
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={menuAnchorEl}
                keepMounted
                open={isMenuOpen}
                onClose={this.handleMenuClose}
                className={classes.addItemMenu}
              >
                <div
                data-testId="OpenCsvModalBtnDisable"
                className={classes.menuOptionsDisable}
                  >
                  <p className={classes.buttonTitleDisable}>
                    {configJSON.InProgressTitleMsg}
                  </p>
                  <p className={classes.optionTextDisable} >
                  {configJSON.InProgressMsg}
                  </p>
                </div>
              </Menu>
            </Box>
          </Grid>
            </>
           ) : ( 
            <Grid item lg={4} md={4}>
            <>
              <Box className={classes.MenuActionBox}>
                {(!this.state.checkedImageCatalogueList || this.state.noSearchCategories) ? (
                  <Tooltip title={configJSON.toolTipTitle} placement="top-end" classes={{ tooltip: classes.customTooltip }} style={{position:"relative"}}>
                  <span className={classes.deleteItems}>
                  {configJSON.deleteItems}
                  </span>
                  </Tooltip>
                ) : (
                  <>
                    <Typography className={classes.deleteItems}
                     onClick={() => {
                      this.setState({
                        openDeleteDialog: true,
                      });
                    }}>
                    {configJSON.deleteItems}
                    </Typography>
                  </>
                )}
                <Button
                  variant="contained"
                  className={classes.addMenuItemButton}
                  style={{ background: "#00D659" }}
                  data-testId="addMenuItem"
                  onClick={this.handleMenuClick}
                >
                  {configJSON.addItem}
                  {this.renderToggleIcon(isMenuOpen)}
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={menuAnchorEl}
                  keepMounted
                  open={isMenuOpen}
                  onClose={this.handleMenuClose}
                  className={classes.addItemMenu}
                >
                  {this.state.isCsvUploadEnabled && <div
                  data-testId="OpenCsvModalBtn"
                  className={classes.menuOptions}
                  onClick={() => {
                    this.handleCsvClick(false);
                    this.handleMenuClose();
                  }}
                    >
                    <p
                    className={classes.buttonTitle}
                    >
                      {configJSON.importMenu}
                    </p>
                    <p className={classes.optionText1} >
                    {configJSON.importMenuMsg}
                    </p>
                  </div>}
                  <div
                    onClick={() => {
                      this.navigateToItemsLibrary();
                      this.handleMenuClose();
                    }}
                  className={classes.menuOptions}
                  >
                    <p
                      onClick={this.handleClose}
                      className={classes.buttonTitle}
                    >
                      
                      {configJSON.builderLibrary}
                    </p>
                    <p className={classes.optionText2} >
                    {configJSON.builderLibraryMsg}
                    </p>
                  </div>
                  <div 
                  data-testId="OpenAddItemsBtn"
                  className={classes.menuOptions}
                  onClick={() => {
                    this.openAddItemsForm();
                    this.handleMenuClose();
                  }}
                  >
                    <p
                    className={classes.buttonTitle}
                    onClick={this.handleClose}
                  >
                    {configJSON.addItem}
                  </p>
                  <p className={classes.optionText3} >
                    {configJSON.addItemMsg}
                  </p>
                  </div>
                  {
                   this.state.isCsvUsingAiEnabled && this.state.showAddItemAI && (
                      <div
                        data-testId="OpenAddItemAIBtn"
                        className={classes.addItemOption}
                        onClick={() => {
                          this.handleCsvClick(true);
                          this.handleMenuClose();
                        }}
                      >
                        <p className={classes.buttonTitle} onClick={this.handleClose}>
                          {configJSON.addItemAI}
                        </p>
                        <p className={classes.optionText3} >
                          {configJSON.addItemAIMsg}
                        </p>
                      </div>
                    )
                  }
                </Menu>
              </Box>
            </>
          </Grid>
            )}
          
        </Grid>
        <Grid item lg={12} md={12} xs={12} style={{ margin: "30px 0px" }}>
          {this.searchFilterFoodLable(classes)}
        </Grid>
        <Grid item lg={12} md={12} xs={12}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell >
                  <Checkbox
                    className={classes.checkAllCheckbox}
                    onChange={this.handleSelectAllCheckboxChange}
                    checked={this.state.allChecked}
                    data-testId="checkAll-Checkbox"
                  />
                </TableCell>
                <TableCell className={classes.headerText}>
                  {configJSON.itemName}
                </TableCell>
                <TableCell className={classes.headerText}>Price</TableCell>
                <TableCell className={classes.headerText}>
                  Discounted Price
                </TableCell>
                <TableCell className={classes.headerText}>Status</TableCell>
                <TableCell className={classes.headerText}>Category</TableCell>
                <TableCell className={classes.headerText}>
                  Availability
                </TableCell>
                <TableCell className={classes.headerText}>Food Label</TableCell>
                <TableCell className={classes.headerText} align="right">
                  Edit
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ background: "#fff" }}>
              {this.state.productsData &&
                this.state.productsData.length > 0 &&
                !this.state.noSearchCategories ? (
                this.state.productsData.map((data: any, index: any) => {
                  return (
                    <TableRow
                      key={data.attributes.id}
                      style={{ border: "2px solid #ECEDF0" }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        className={`${classes.dataText} ${classes.checkboxTableCell}`}
                      >
                        {this.renderCheckbox(data)}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.dataText}
                      >
                        {data.attributes.name}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.dataText}
                      >
                        {data.attributes.price ?
                          <>
                            {this.currencyType}
                            {data.attributes.price}
                          </> : "N/A"}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.dataText}
                      >
                        {this.discountCheck(data)}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <FormGroup>
                          <FormControlLabel
                            data-testId="editStatusProduct"
                            control={
                              <IOSSwitch
                                value={this.state.product_status}
                                checked={Boolean(data.attributes.status)}
                                disabled={!data.attributes.price}
                                onChange={() => {
                                  this.editStatusProduct(
                                    data.attributes.categories[0],
                                    !data.attributes.status,
                                    data.id
                                  );
                                  this.setState({ productStatus: true });
                                }}
                                name={data.id}
                              />
                            }
                            label=""
                          />
                        </FormGroup>
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.dataText}
                      >
                        {data?.attributes?.categories[0]
                          .map((categorieVal: any) => categorieVal[0])
                          .join(", ")}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          fontFamily: "Rubik",
                          fontSize: "12px",
                          fontWeight: "400",
                          lineHeight: "16px",
                          color: this.getAvailabilityColor(data),
                            
                        }}
                      >
                        {this.getAvailabilityText(data)}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {this.foodLabelBlock(data, classes)}
                      </TableCell>
                      <TableCell align="right">
                        <img
                          src={editIcon}
                          style={{ cursor: "pointer" }}
                          data-testId="editProductDetails"
                          onClick={() => {
                            this.editProductDetails(data.attributes, data.id);
                          }}
                          alt="edit icon"
                        />
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow style={{ border: "2px solid #ECEDF0" }}>
                  <TableCell component="th" scope="row" colSpan={8}>
                    <Grid
                      item
                      lg={12}
                      md={12}
                      xs={12}
                      style={{ background: "#fff", margin: "10px 0px" }}
                    >
                      <Grid item className={classes.categoriesContent}>
                        <Typography style={{ marginBottom: "10px" }}>
                          {this.state.appliedFoodLables.length > 0 ? <img src={noProducts} alt="no products" /> : <img src={noCategories} alt="no categories" />}
                        </Typography>
                        <Typography
                          variant="h5"
                          style={{
                            fontFamily: "Rubik",
                            fontSize: "20px",
                            fontWeight: 500,
                            lineHeight: "26px",
                            letterSpacing: "-0.02em",
                            marginBottom: "5px",
                          }}
                        >
                          No results found
                        </Typography>
                        <Typography
                          style={{
                            fontFamily: "Rubik",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "22px",
                            marginBottom: "10px",
                            color: "#83889E",
                            width: "395px"
                          }}
                        >{this.foodLabelsMessage()}
                        </Typography>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    );
  }

  selectCategory = (
    setFieldValue,
    touched,
    errors,
    handleBlur,
    classes,
    filter
  ) => {
    return (
      <>
        <Prompt message={this.handleMessage} />
        <Autocomplete
          name="product_category"
          data-testId="product_category"
          multiple
          options={this.state.categoriesData}
          value={this.state.product_category}
          inputValue={this.state.InputValue}
          onInputChange={(event, inputVal) => {
            this.setState({ InputValue: inputVal });
          }}
          onChange={(event, inputVal) => {
            const categories = [];
            inputVal.forEach((newValue: any) => {
              if (typeof newValue === "string") {
                categories.push(newValue);
              } else if (newValue && newValue.id == "") {
                this.createCategory(newValue.name);
              } else {
                categories.push(newValue);
              }
            });
            this.setState({
              product_category: categories ?? [],
              openSubmit: true,
            });
            setFieldValue("product_category", inputVal);
          }}
          disableCloseOnSelect={true}
          getOptionLabel={(option) => {
            if (typeof option === "string") {
              return option;
            }
            if (option.id == "") {
              return (
                <span
                  style={{
                    fontFamily: "Rubik",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "19px",
                    color: "#5500CC",
                  }}
                >
                  {option.name}
                </span>
              );
            }
            return option.name;
          }}
          renderOption={(option) =>
            option.id == "" ? (
              <span
                style={{
                  fontFamily: "Rubik",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "19px",
                  color: "#5500CC",
                }}
              >
                + Add &lt;{option.name}&gt;
              </span>
            ) : (
              <span
                style={{
                  fontFamily: "Rubik",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "22px",
                  color: "#475569",
                }}
              >
                {option.name}
              </span>
            )
          }
          disablePortal={true}
          ListboxProps={{
            style: {
              maxHeight: "150px",
              margin: "10px",
            },
          }}
          getOptionSelected={(option, value) => option.id === value.id}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            const { inputValue } = params;
            const isExisting = options.some(
              (option) =>
                inputValue.toLocaleLowerCase().trim() ===
                option.name.toLocaleLowerCase().trim()
            );
            if (inputValue !== "" && !isExisting) {
              filtered.push({
                id: "",
                name: inputValue,
              });
            }
            return filtered;
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              className={classes.placeholderText}
              placeholder="Select"
              variant="outlined"
            />
          )}
          renderTags={(tagValue, getTagProps) => {
            if (tagValue?.length == 0) {
              return "Select";
            } else {
              return tagValue.length + " categories selected";
            }
          }}
          error={Boolean(touched.product_category && errors.product_category)}
          onBlur={handleBlur}
        />
        {Boolean(touched.product_category && errors.product_category) && (
          <FormHelperText error={true} className="MuiFormHelperText-contained">
            Select atleast one category
          </FormHelperText>
        )}
        <Grid>
          {this.state.product_category &&
            this.state.product_category.length > 0 &&
            (this.state.product_category as string[])?.map((value: any) => {
              return (
                <Chip
                  className={classes.chipText}
                  key={value.id}
                  label={value.name}
                  style={{ margin: "5px 5px 5px 0px" }}
                />
              );
            })}
        </Grid>
      </>
    );
  };
  selectDietary = (setFieldValue, classes) => {
    return (
      <>
        <Select
          name="product_dietary"
          multiple
          fullWidth
          value={this.state.product_dietary}
          variant="outlined"
          data-testId="product_dietary"
          onChange={(event) => {
            this.handleDietaryChange(event);
            setFieldValue("product_dietary", event.target.value);
          }}
          displayEmpty
          className={classes.selectFoodLable}
          renderValue={(selected: any) => {
            if (selected.length == 0) {
              return "Select Dietary";
            } else {
              return selected.length + " Dietary selected";
            }
          }}
        >
          {this.state.dietaryData.map((item: any) => {
            return (
              <MenuItem
                key={item.key}
                value={item.value}
                className={classes.menuList}
              >
                <PurpleCheckbox
                  className={classes.menuItemCheckbox}
                  checked={this.state.product_dietary.indexOf(item.value) > -1}
                  icon={<span className={classes.icon} />}
                  checkedIcon={<span className={classes.checkedIcon} />}
                />
                <Grid
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Grid
                    style={{
                      marginRight: "5px",
                    }}
                  >
                    <img
                      src={item.img}
                      alt={item.name}
                      style={{ height: "28px", width: "28px" }}
                    />
                  </Grid>
                  <Grid className={classes.menuItemName}>{item.name}</Grid>
                </Grid>
              </MenuItem>
            );
          })}
        </Select>
        {this.state.product_dietary &&
          (this.state.product_dietary as string[]).map((value) => {
            const dietary: any = [];
            this.state.dietaryData.find((dieraryVal: any) => {
              if (dieraryVal.value == value) {
                dietary.push({
                  img: dieraryVal.img,
                  value: dieraryVal.value,
                  color: dieraryVal.color,
                });
              }
            });
            return (
              <Chip
                avatar={<Avatar src={dietary[0]?.img} />}
                key={dietary[0]?.value}
                label={dietary[0]?.value}
                style={{
                  margin: "5px 5px 5px 0px",
                  background: `${dietary[0]?.color}`,
                  fontFamily: "Rubik",
                  fontSize: "12px",
                  fontWeight: "400",
                  lineHeight: "16px",
                  color: "#334155",
                }}
              />
            );
          })}
      </>
    );
  };
  selectAllergen = (setFieldValue, classes) => {
    return (
      <>
        <Select
          name="product_allergens"
          multiple
          value={this.state.product_allergens}
          onChange={(event: any) => {
            this.handleAllergensChange(event);
            setFieldValue("product_allergens", event.target.value);
          }}
          variant="outlined"
          fullWidth
          displayEmpty
          data-testId="product_allergens"
          className={classes.selectFoodLable}
          renderValue={(selected: any) => {
            if (selected.length === 0) {
              return "Select Allergens";
            } else {
              return selected.length + " Allergens selected";
            }
          }}
        >
          {this.state.allergenData.map((item: any) => {
            return (
              <MenuItem
                key={item.id}
                value={item.value}
                className={classes.menuList}
              >
                <PurpleCheckbox
                  className={classes.menuItemCheckbox}
                  checked={
                    this.state.product_allergens.indexOf(item.value) > -1
                  }
                  icon={<span className={classes.icon} />}
                  checkedIcon={<span className={classes.checkedIcon} />}
                />
                <Grid
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Grid
                    style={{
                      marginRight: "5px",
                    }}
                  >
                    <img
                      src={item.img}
                      alt={item.name}
                      style={{ width: "28px", height: "28px" }}
                    />
                  </Grid>
                  <Grid className={classes.menuItemName}>{item.name}</Grid>
                </Grid>
              </MenuItem>
            );
          })}
        </Select>
        {this.state.product_allergens &&
          (this.state.product_allergens as string[]).map((value: any) => {
            const allergens: any = [];
            this.state.allergenData.find((allergenVal: any) => {
              if (allergenVal.value == value) {
                allergens.push({
                  img: allergenVal.img,
                  value: allergenVal.value,
                  color: allergenVal.color,
                });
              }
            });
            return (
              <Chip
                avatar={<Avatar src={allergens[0]?.img} />}
                key={allergens[0]?.value}
                label={allergens[0]?.value}
                style={{
                  margin: "5px 5px 5px 0px",
                  background: `${allergens[0]?.color}`,
                  fontFamily: "Rubik",
                  fontSize: "12px",
                  fontWeight: "400",
                  lineHeight: "16px",
                  color: "#334155",
                }}
              />
            );
          })}
      </>
    );
  };
  spiceLevelCheck = (selected) => {
    switch (selected) {
      case "Spicy":
        return spicy_icon;
      case "Mild":
        return mild_icon;
      case "Extra Hot":
        return extraspicy_icon;
      default:
        return spicy_icon;
    }
  };
  selectSpicyLevel = (setFieldValue, classes) => {
    return (
      <Select
        name="product_spicelevel"
        value={this.state.product_spicelevel}
        onClick={(event) => {
          this.handleClickSpiceLevelChange(event);
        }}
        onChange={(event) => {
          this.handleSpiceLevelChange(event);
          setFieldValue("product_spicelevel", event.target.value);
        }}
        fullWidth
        displayEmpty
        variant="outlined"
        data-testId="product_spicelevel"
        className={classes.selectFoodLable}
        renderValue={(selected: any) => {
          if (selected?.length === 0 || selected === undefined) {
            return "Select Spice Level";
          } else {
            return (
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={this.spiceLevelCheck(selected)}
                  style={{
                    marginRight: "5px",
                    height: "24px",
                    width: "24px",
                  }}
                />
                {selected}
              </span>
            );
          }
        }}
      >
        {this.state.spiceLevelData.map((item: any) => {
          return (
            <MenuItem
              key={item.id}
              value={item.value}
              className={classes.menuList}
            >
              <Grid
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Grid
                  style={{
                    marginRight: "5px",
                  }}
                >
                  <img
                    src={item.img}
                    alt={item.name}
                    style={{ width: "28px", height: "28px" }}
                  />
                </Grid>
                <Grid className={classes.menuItemName}>{item.name}</Grid>
              </Grid>
            </MenuItem>
          );
        })}
      </Select>
    );
  };
  selectAvailability = (
    setFieldValue,
    touched,
    errors,
    handleBlur,
    classes
  ) => {
    return (
      <>
        <Select
          name="product_stock"
          fullWidth
          variant="outlined"
          value={this.state.product_stock}
          className={classes.placeholderText}
          onChange={(event) => {
            this.setState({ product_stock: event.target.value, openSubmit: true });
            setFieldValue("product_stock", event.target.value);
          }}
          displayEmpty
          data-testId="product_stock"
          error={Boolean(touched.product_stock && errors.product_stock)}
          renderValue={(selected: any) => {
            if (selected.length === 0) {
              return "Select";
            } else {
              if (selected == "in_stock") {
                return (
                  <Grid
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid
                      style={{
                        background: "green",
                        borderRadius: "50%",
                        padding: "6px",
                        margin: "5px",
                      }}
                    />
                    <Grid>Available</Grid>
                  </Grid>
                );
              }
              if (selected == "out_of_stock") {
                return (
                  <Grid
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid
                      style={{
                        background: "red",
                        borderRadius: "50%",
                        padding: "6px",
                        margin: "5px",
                      }}
                    />
                    <Grid>Not available</Grid>
                  </Grid>
                );
              }
            }
          }}
          onBlur={handleBlur}
        >
          <MenuItem value="in_stock">
            <Grid
              style={{
                background: "green",
                borderRadius: "50%",
                padding: "6px",
                margin: "5px",
              }}
            />
            Available
          </MenuItem>
          <MenuItem value="out_of_stock">
            <Grid
              style={{
                background: "red",
                borderRadius: "50%",
                padding: "6px",
                margin: "5px",
              }}
            />
            Not available
          </MenuItem>
        </Select>
        {Boolean(touched.product_stock && errors.product_stock) && (
          <FormHelperText error={true} className="MuiFormHelperText-contained">
            Select atleast one
          </FormHelperText>
        )}
      </>
    );
  };

  priceWithDiscountBlock = (
    setFieldValue,
    touched,
    errors,
    handleBlur,
    classes
  ) => {
    return (
      <>
        <Grid item lg={12} md={12} sm={12} className={classes.bottomSpace}>
          <Typography variant="h6" component="h6" className={classes.titleText}>
            Price *
          </Typography>
          <Grid
            container
            item
            sm={12}
            md={12}
            lg={12}
            style={{ alignItems: "center" }}
          >
            <Grid item lg={5} md={5} sm={5}>
              <TextField
                value={this.state.product_price}
                type="text"
                name="product_price"
                data-testId="product_price"
                className={classes.placeholderText}
                onChange={(event) => {
                  if (this.isDecimalValid(event.target.value)) {
                    this.setState({
                      product_price: event.target.value,
                      openSubmit: true,
                    });
                    setFieldValue("product_price", event.target.value);
                  }
                }}
                error={Boolean(touched.product_price && errors.product_price)}
                onBlur={handleBlur}
                helperText={touched.product_price && errors.product_price}
                fullWidth
                variant="outlined"
                placeholder={this.state.isEdit ? "Edit price" : "Enter price"}
              />
            </Grid>
            <Grid item lg={1} md={1} sm={1} />
            <Grid item lg={4} md={4} sm={4}>
              <FormGroup>
                <FormControlLabel
                  data-testId="discount_checked"
                  control={
                    <IOSSwitch
                      checked={Boolean(this.state.discount_checked)}
                      onChange={() => {
                        this.setState({
                          discount_checked: !this.state.discount_checked,
                          openSubmit: true,
                        });
                        setFieldValue(
                          "discount_checked",
                          !this.state.discount_checked
                        );
                        if (this.state.discount_checked) {
                          this.setState({
                            product_discountValue: 0,
                            product_discountPrice: 0,
                          });
                        }
                      }}
                      name="discount_checked"
                    />
                  }
                  className={classes.switchLabel}
                  label={
                    this.state.discount_checked
                      ? "Discount Added"
                      : "No discount"
                  }
                />
              </FormGroup>
            </Grid>
          </Grid>
        </Grid>

        <Grid item lg={5} md={5} sm={5} className={classes.bottomSpace}>
          {this.state.discount_checked && (
            <Grid container item sm={12} md={12} lg={12}>
              <Grid item sm={5} md={5} lg={5}>
                <Typography
                  variant="h6"
                  component="h6"
                  className={classes.titleText}
                >
                  Discount
                </Typography>
                <TextField
                  variant="outlined"
                  name="product_discountValue"
                  type="text"
                  placeholder="discount"
                  value={this.state.product_discountValue}
                  className={classes.placeholderText}
                  data-testId="product_discountValue"
                  error={Boolean(
                    touched.product_discountValue &&
                    errors.product_discountValue
                  )}
                  onBlur={handleBlur}
                  helperText={
                    touched.product_discountValue &&
                    errors.product_discountValue
                  }
                  onChange={(event) => {
                    this.handleDiscountValueChange(event);
                    setFieldValue("product_discountValue", event.target.value);
                  }}
                />
              </Grid>
              <Grid
                item
                sm={1}
                md={1}
                lg={1}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "25px",
                }}
              >
                %
              </Grid>
              <Grid item sm={6} md={6} lg={6}>
                <Typography
                  variant="h6"
                  component="h6"
                  className={classes.titleText}
                >
                  Price after discount
                </Typography>
                <TextField
                  variant="outlined"
                  name="product_discountPrice"
                  type="text"
                  style={{ background: "#F1F5F9" }}
                  className={classes.placeholderText}
                  disabled
                  placeholder="discount price"
                  value={this.state.product_discountPrice}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </>
    );
  };

  addEditProductBlock = (classes, filter) => {
    return (
      <>
        <Grid item xs={12} className={classes.backArrow}>
          <img
            src={backIcon}
            data-testId="backBtn"
            onClick={() => this.pressBackEditAddItem()}
            alt="backIcon"
            style={{
              cursor: "pointer",
              marginRight: "5px",
              height: "12px",
              width: "12px",
            }}
          />
          <Typography
            style={{
              fontFamily: "Rubik",
              fontSize: "12px",
              fontWeight: "400",
              lineHeight: "16px",
              color: "#676B7E",
            }}
          >
            Back
          </Typography>
        </Grid>
        <Formik
          innerRef={this.formikRef}
          initialValues={{
            product_title: this.state.product_title,
            product_desc: this.state.product_desc,
            product_price: this.state.product_price,
            product_category: this.state.product_category,
            product_discountValue: this.state.product_discountValue,
            product_stock: this.state.product_stock,
            product_image: this.state.product_selectedImage,
            packageHeight: this.state.packageHeight,
            packageWidth: this.state.packageWidth,
            packageBreadth: this.state.packageBreadth,
            packageWeight: this.state.packageWeight
          }}
          data-testId="formik"
          onSubmit={(values) => {
            if (this.state.product_Id) {
              this.setState({ openSubmit: false });
              this.editProduct();
            } else {
              if (values.product_image && !this.state.image_check) {
                this.setState({ image_check: false, openSubmit: false });
                this.addProduct();
              } else {
                this.setState({ image_check: true });
              }
            }
          }}
          validationSchema={validationSchema}
        >
          {({
            errors,
            touched,
            values,
            handleBlur,
            setFieldValue,
            handleSubmit,
          }) => {
            const errorsData = this.getItemFieldValidations(touched, errors);
            return (
              <form onSubmit={handleSubmit} noValidate autoComplete="off">
                <Grid
                  item
                  lg={12}
                  md={12}
                  xs={12}
                  style={{ display: "flex", margin: "30px 0px" }}
                >
                  <Grid item lg={6} md={6} xs={6}>
                    <Typography
                      variant="h5"
                      style={{
                        fontFamily: "Rubik",
                        fontSize: "26px",
                        fontWeight: "500",
                        lineHeight: "30.81px",
                        color: "#3C3E49",
                      }}
                    >
                      {this.state.isEdit ? "Edit" : "Add a"} Menu item
                    </Typography>
                  </Grid>
                  <Grid item lg={6} md={6} xs={6} style={{ textAlign: "right" }}>
                    <Button
                      hidden
                      ref={this.submitRef}
                      variant="contained"
                      style={{ display: "none" }}
                      className={classes.categoryButton}
                      data-testId="categorySubmit"
                      type="submit"
                    >
                      Save Changes
                    </Button>
                  </Grid>
                </Grid>
                <Grid item sm={12} md={12} lg={12} style={{ display: "flex" }}>
                  <Grid
                    item
                    sm={9}
                    md={9}
                    lg={9}
                    style={{
                      marginRight: "15px",
                      borderRadius: "3px",
                      border: "2px solid #ECEDF0",
                    }}
                  >
                    <Card style={{ alignItems: "center" }}>
                      <CardContent>
                        <Typography
                          component="h5"
                          style={{
                            fontFamily: "Rubik",
                            fontWeight: "500",
                            fontSize: "20px",
                            lineHeight: "26px",
                            color: "#3C3E49",
                            paddingBottom: "20px",
                          }}
                        >
                          Product Details
                        </Typography>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          sm={12}
                          className={classes.bottomSpace}
                        >
                          <Grid
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              variant="h6"
                              component="h6"
                              className={classes.titleText}
                            >
                              Title *
                            </Typography>
                            <span className={classes.countText}>
                              {this.state.titleCount}/36
                            </span>
                          </Grid>
                          <TextField
                            id="addCategory"
                            name="product_title"
                            value={this.state.product_title}
                            type="text"
                            size="small"
                            data-testId="product_title"
                            className={classes.placeholderText}
                            onChange={(event) => {
                              this.setState({
                                product_title: event.target.value.replace(
                                  /\s+/g,
                                  " "
                                ),
                                titleCount: event.target.value.replace(/\s+/g, " ")
                                  .length,
                                openSubmit: true,
                                setTouched: false,
                              });
                              setFieldValue(
                                "product_title",
                                event.target.value.replace(/\s+/g, " ")
                              );
                            }}
                            fullWidth
                            variant="outlined"
                            placeholder={
                              this.state.isEdit ? "Edit title" : "Enter title"
                            }
                            onBlur={handleBlur}
                            error={
                              Boolean(
                                touched.product_title && errors.product_title
                              ) || this.state.setTouched
                            }
                            helperText={
                              this.state.setTouched
                                ? this.state.errorText
                                : touched.product_title && errors.product_title
                            }
                          />
                        </Grid>

                        <Grid
                          item
                          lg={7}
                          md={7}
                          sm={7}
                          className={classes.bottomSpace}
                        >
                          <Grid
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              variant="h6"
                              component="h6"
                              className={classes.titleText}
                            >
                              Description *
                            </Typography>
                            <span className={classes.countText}>
                              {this.state.descCount}/300
                            </span>
                          </Grid>
                          <TextField
                            id="addCategory"
                            value={this.state.product_desc}
                            name="product_desc"
                            type="text"
                            data-testId="product_desc"
                            className={classes.placeholderText}
                            onChange={(event) => {
                              this.setState({
                                product_desc: event.target.value,
                                descCount: event.target?.value?.length,
                                openSubmit: true,
                              });
                              setFieldValue("product_desc", event.target.value);
                            }}
                            fullWidth
                            multiline
                            minRows={4}
                            variant="outlined"
                            placeholder={
                              this.state.isEdit
                                ? "Edit description"
                                : "Enter description"
                            }
                            error={Boolean(
                              touched.product_desc && errors.product_desc
                            )}
                            onBlur={handleBlur}
                            helperText={
                              touched.product_desc && errors.product_desc
                            }
                          />
                        </Grid>

                        <Grid
                          item
                          lg={5}
                          md={5}
                          sm={5}
                          className={classes.bottomSpace}
                        >
                          <Typography
                            variant="h6"
                            component="h6"
                            className={classes.titleText}
                          >
                            Categories *
                          </Typography>
                          {this.selectCategory(
                            setFieldValue,
                            touched,
                            errors,
                            handleBlur,
                            classes,
                            filter
                          )}
                        </Grid>
                        <Grid
                          item
                          lg={10}
                          md={10}
                          sm={10}
                          className={classes.bottomSpace}
                        >
                          <Typography
                            variant="h6"
                            component="h6"
                            className={classes.titleText}
                          >
                            Image *
                          </Typography>
                          <Grid container lg={12} md={12} sm={12}>
                            <Grid item lg={3} md={4} sm={5}>
                              <Card
                                style={{
                                  border: this.state.image_check
                                    ? "2px dashed #f44336"
                                    : "2px dashed #C0C3CE",
                                  borderRadius: "10px",
                                  textAlign: "center",
                                  padding: "30px 10px",
                                  boxShadow: "none",
                                }}
                              >
                                <CardContent
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                  }}
                                >
                                  <Grid
                                    onClick={() => this.imgRef.current.click()}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <Typography
                                      variant="h5"
                                      component="h5"
                                      className={classes.imageText}
                                    >
                                      +
                                    </Typography>
                                    <Typography
                                      variant="h5"
                                      component="h5"
                                      className={classes.imageText}
                                    >
                                      {this.state.product_previewImage ||
                                        this.state.product_selectedImage
                                        ? "Edit "
                                        : "Add "}
                                      image
                                    </Typography>
                                    <Typography
                                      variant="h5"
                                      component="h5"
                                      style={{
                                        fontFamily: "Rubik",
                                        fontSize: "12px",
                                        fontWeight: "400",
                                        lineHeight: "16px",
                                        color: "#AFB2C0",
                                      }}
                                    >
                                      Max 1024 x 1024px
                                    </Typography>

                                    <Button
                                      style={{
                                        fontFamily: "Rubik",
                                        fontWeight: "500",
                                        fontSize: "20px",
                                        color: "#C0C3CE",
                                        display: "none",
                                      }}
                                    >
                                      <input
                                        ref={this.imgRef}
                                        name="product_image"
                                        type="file"
                                        hidden
                                        accept="image/*"
                                        data-testId="product_image"
                                        onChange={(event: any) => {
                                          this.handleImageChange(event);
                                          setFieldValue(
                                            "product_image",
                                            event.target.files[0]
                                          );
                                        }}
                                      />
                                    </Button>
                                  </Grid>
                                </CardContent>
                              </Card>
                            </Grid>
                            <Grid
                              item
                              lg={9}
                              md={8}
                              sm={7}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                paddingLeft: "20px",
                              }}
                            >
                              {this.state.product_previewImage && (
                                <div>
                                  <img
                                    src={this.state.product_previewImage}
                                    alt="Selected"
                                    style={{
                                      maxWidth: "100%",
                                      maxHeight: "200px",
                                    }}
                                  />
                                </div>
                              )}
                            </Grid>
                            {this.state.image_check && (
                              <FormHelperText
                                error={true}
                                className="MuiFormHelperText-contained"
                              >
                                {this.state.imageError}
                              </FormHelperText>
                            )}
                          </Grid>
                        </Grid>
                        {this.priceWithDiscountBlock(
                          setFieldValue,
                          touched,
                          errors,
                          handleBlur,
                          classes
                        )}
                        <Grid
                          item
                          lg={12}
                          md={12}
                          sm={12}
                          className={classes.bottomSpace}
                        >
                          <Typography
                            variant="h6"
                            component="h6"
                            className={classes.titleText}
                          >
                            Food Label
                          </Typography>

                          <Grid
                            container
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            spacing={2}
                          >
                            <Grid item lg={4} md={4} sm={4}>
                              {this.selectDietary(setFieldValue, classes)}
                            </Grid>
                            <Grid item lg={4} md={4} sm={4}>
                              {this.selectAllergen(setFieldValue, classes)}
                            </Grid>
                            <Grid item lg={4} md={4} sm={4}>
                              {this.selectSpicyLevel(setFieldValue, classes)}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} className={classes.packageDetailsGridItem}>
                          <Typography
                            className={classes.packageDetailsTitleText}
                          >
                            {configJSON.packageDetails}
                          </Typography>
                          <Grid
                            container
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            spacing={2}
                          >
                            <Grid item lg={4} md={4} sm={4}>
                              <Typography
                                className={classes.packageDetailsSubTitleText}
                              >
                                {configJSON.packageHeight}
                              </Typography>
                              <TextField
                                name="packageHeight"
                                value={this.state.packageHeight}
                                type="text"
                                className={classes.packageDetailTextField}
                                onChange={this.onChangePackageHeight}
                                fullWidth
                                variant="outlined"
                                placeholder={configJSON.packageHeight}
                                onBlur={handleBlur}
                                error={errorsData.packageHeightError}
                                helperText={errorsData.packageHeightErrorText}
                              />
                            </Grid>
                            <Grid item lg={4} md={4} sm={4}>
                              <Typography
                                className={classes.packageDetailsSubTitleText}
                              >
                                {configJSON.packageBreadth}
                              </Typography>
                              <TextField
                                name="packageBreadth"
                                value={this.state.packageBreadth}
                                type="text"
                                className={classes.packageDetailTextField}
                                onChange={this.onChangePackageBreadth}
                                fullWidth
                                variant="outlined"
                                placeholder={configJSON.packageBreadth}
                                onBlur={handleBlur}
                                error={errorsData.packageBreadthError}
                                helperText={errorsData.packageBreadthErrorText}
                                required
                              />
                            </Grid>
                            <Grid item lg={4} md={4} sm={4}>
                              <Typography
                                className={classes.packageDetailsSubTitleText}
                              >
                                {configJSON.packageWidth}
                              </Typography>
                              <TextField
                                name="packageWidth"
                                value={this.state.packageWidth}
                                type="text"
                                className={classes.packageDetailTextField}
                                onChange={this.onChangePackageWidth}
                                fullWidth
                                placeholder={configJSON.packageWidth}
                                variant="outlined"
                                onBlur={handleBlur}
                                error={errorsData.packageWidthError}
                                helperText={errorsData.packageWidthErrorText}
                              />
                            </Grid>
                            <Grid item lg={4} md={4} sm={4}>
                              <Typography
                                className={classes.packageDetailsSubTitleText}
                              >
                                {configJSON.packageWeight}
                              </Typography>
                              <TextField
                                name="packageWeight"
                                value={this.state.packageWeight}
                                type="text"
                                className={classes.packageDetailTextField}
                                onChange={this.onChangePackageWeight}
                                fullWidth
                                variant="outlined"
                                onBlur={handleBlur}
                                placeholder={configJSON.packageWeight}
                                error={errorsData.packageWeightError}
                                helperText={errorsData.packageWeightErrorText}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        {this.renderVaraints({}, classes)}
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item sm={3} md={3} lg={3}>
                    <Card
                      style={{
                        borderRadius: "3px",
                        boxShadow: "none",
                        border: "2px solid #ECEDF0",
                      }}
                    >
                      <CardContent style={{ padding: "0px" }}>
                        <Grid item sm={12} style={{ padding: "16px" }}>
                          <Typography
                            variant="h6"
                            style={{
                              fontFamily: "Rubik",
                              fontWeight: "500",
                              fontSize: "20px",
                              color: "#3C3E49",
                              lineHeight: "26px",
                              paddingBottom: "10px",
                            }}
                          >
                            Status
                          </Typography>

                          <Grid item sm={12}>
                            <FormGroup>
                              <FormControlLabel
                                data-testId="active_checked"
                                control={
                                  <IOSSwitch
                                    checked={this.state.product_status}
                                    onChange={() => {
                                      this.setState({
                                        product_status:
                                          !this.state.product_status,
                                        openSubmit: true,
                                      });
                                    }}
                                    name="product_status"
                                  />
                                }
                                className={classes.switchLabel}
                                label={
                                  this.state.product_status
                                    ? "Active"
                                    : "Not active"
                                }
                              />
                            </FormGroup>
                            <Typography
                              style={{
                                fontFamily: "Rubik",
                                fontSize: "12px",
                                fontWeight: "400",
                                lineHeight: "16px",
                                color: "#83889E",
                              }}
                            >
                              Your product is not visible on your store
                            </Typography>
                          </Grid>
                        </Grid>
                        <hr style={{ border: "1px solid #E8E8E8" }} />

                        <Grid
                          item
                          sm={12}
                          style={{ marginBottom: "10px", padding: "16px" }}
                        >
                          <Typography
                            variant="h6"
                            component="h6"
                            className={classes.titleText}
                          >
                            Availability *
                          </Typography>
                          {this.selectAvailability(
                            setFieldValue,
                            touched,
                            errors,
                            handleBlur,
                            classes
                          )}
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </form>
            )
          }}
        </Formik>
      </>
    );
  };
  variantsContentTable = (classes: any) => {
    if (!this.state.productVariants?.length) {
      return (
        <>
          <Typography
            style={{
              fontFamily: "Rubik",
              fontSize: "14px",
              fontWeight: "400",
              lineHeight: "24px",
              color: "#3C3E49",
              marginTop: "8px",
            }}
          >
            To offer customisations on this menu item, add them below. For
            example- Crust Types, Size, Toppings, Ingredients, etc.
          </Typography>
        </>
      );
    }
    return (
      <>
        <Typography
          style={{
            fontFamily: "Rubik",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "24px",
            color: "#3C3E49",
            marginTop: "8px",
          }}
        >
          If this menu item has different options, like crust or toppings, add
          them below
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.variantTableTitle} style={{}}>
                {" "}
                Title
              </TableCell>
              <TableCell className={classes.variantTableTitle} style={{}}>
                {" "}
                Options
              </TableCell>
              <TableCell className={classes.variantTableTitle} style={{}}>
                {" "}
                Price
              </TableCell>
              <TableCell
                className={classes.variantTableTitle}
                style={{
                  paddingRight: "40px",
                }}
                align="center"
              >
                Edit
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ border: "2px", background: "#fff" }}>
            {this.state.productVariants &&
              this.state.productVariants.map(
                (variantArray: any, index: any) => {
                  let variant = { ...variantArray };
                  variant.options = variantArray?.options.filter(
                    (option) => option.data.attributes.status
                  );

                  return (
                    <>
                      <TableRow
                        key={variant.id}
                        style={{ border: "2px solid #ECEDF0" }}
                      >
                        <TableCell
                          rowSpan={
                            variant?.options?.length == 0
                              ? 1
                              : variant?.options?.length
                          }
                          className={classes.tableData}
                        >
                          {variant.title}
                        </TableCell>
                        <TableCell
                          className={classes.tableData}
                          style={{ borderLeft: "2px solid #ECEDF0" }}
                        >
                          {variant?.options.length == 0
                            ? ""
                            : variant?.options[0]?.data.attributes.option_name}
                        </TableCell>
                        <TableCell
                          className={classes.tableData}
                          style={{ borderRight: "2px solid #ECEDF0" }}
                        >
                          {variant?.options.length == 0
                            ? ""
                            : variant?.options[0]?.data.attributes.price}
                        </TableCell>
                        <TableCell
                          rowSpan={
                            variant?.options?.length == 0
                              ? 1
                              : variant?.options?.length
                          }
                          align="center"
                        >
                          <img
                            src={editIcon}
                            style={{ cursor: "pointer" }}
                            data-testId="editVariantBtn"
                            alt="edit icon"
                            onClick={() => {
                              this.setState(
                                {
                                  searchSelectedVariant: variantArray,
                                },
                                () => {
                                  this.setState({ isOpen: true, openSubmit: true, });
                                }
                              );
                            }}
                          />
                          <img
                            src={crossIcon}
                            style={{
                              cursor: "pointer",
                              marginLeft: "15px",
                              height: "18.33px",
                              width: "18.33px",
                            }}
                            data-testId="deleteVariantBtn"
                            onClick={() => {
                              this.setState({
                                productVariants:
                                  this.state.productVariants.filter((productVariant) => variant.id != productVariant.id
                                  ),
                                openSubmit: true,
                              });
                            }}
                            alt="delete icon"
                          />
                        </TableCell>
                      </TableRow>
                      {variant.options
                        .slice(1)
                        .map((option: any, index: any) => {
                          return (
                            <TableRow style={{ border: "2px solid #ECEDF0" }}>
                              <TableCell
                                className={classes.tableData}
                                style={{ borderLeft: "2px solid #ECEDF0" }}
                              >
                                {option.data.attributes.option_name}
                              </TableCell>
                              <TableCell
                                className={classes.tableData}
                                style={{ borderRight: "2px solid #ECEDF0" }}
                              >
                                {option.data.attributes.price}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </>
                  );
                }
              )}
          </TableBody>
        </Table>
      </>
    );
  };
  renderVaraints = (product: any, classes: any) => {
    return (
      <>
        <Typography
          variant="h6"
          component="h6"
          className={classes.titleText}
        >
          Variants
        </Typography>
        {this.variantsContentTable(classes)}
        <Button
          variant="contained"
          className={classes.variantButton}
          style={{
            background: "#00D659",
            marginTop: "12px",
            textTransform: "none",
            boxShadow: "none",
            borderRadius: "3px",
            fontFamily: "Rubik",
            fontSize: "12px",
            fontweight: 700,
            lineHeight: "18px",
            padding: "6px 12px",
            letterSspacing: "0em",
            color: " #3C3E49",
            height: "32px",
            width: "120px",
          }}
          data-testId="addVarintBtn"
          // disabled={!this.state.product_Id}
          onClick={() => {
            this.setState({ searchVariantModalOpen: true, openSubmit: true });
            mixPanelEventHit("Variant added to menu item",{buildCardId : this.mixPanelInfo?.buildCardId, emailId : this.mixPanelInfo?.emailId})
          }}
        >
          Add Variants
        </Button>
        <AddVariantSearch
          data-testId="changeVariantsState"
          onSubmit={this.changeVariantsState}
          selectedVariantsclasses={classes}
          isItemEdit={true}
          isInventoryAdd={true}
          productId={this.state.product_Id}
          parentState={this.state}
          closeSearch={() => {
            this.setState({ searchVariantModalOpen: false });
          }}
          openAdd={() => {
            this.setState({ isOpen: true });
          }}
          closeAdd={() => {
            this.setState({ isOpen: false });
          }}
        />
        {this.state.isOpen && (
          <AddEditVariant
            searchSelectedVariant={{
              variant: {
                title: this.state.searchSelectedVariant.title,
                variant_type: this.state.searchSelectedVariant.variant_type,
                id: this.state.searchSelectedVariant.id,
                min: this.state.searchSelectedVariant.min,
                max: this.state.searchSelectedVariant.max
              },

              options: this.state.searchSelectedVariant.options.map(
                (option) => {
                  return {
                    id: option.data.attributes.id,
                    option_name: option.data.attributes.option_name,
                    price: option.data.attributes.price,
                    status: option.data.attributes.status,
                  };
                }
              ),
            }}
            isEdit={true}
            isEditStatus={true}
            handleClose={(apiresponse) => {
              this.setState({ isOpen: false });
            }}
            modifyDatapass={this.changeVariantsState}
            showEditeSuccessPopup={() => { }}
            showCreateSuccessPopup={() => { }}
          />
        )}
      </>
    );
  };
  changeVariantsState = (productVariant) => {
    this.setState({
      productVariants: [
        ...this.state.productVariants.filter((productVar) => productVar.id !== productVariant.id),
        productVariant,
      ],
    });
  };

  renderScreen(classes: any, filter: any) {
    return !this.state.isAddEdit
      ? this.renderProduct(classes)
      : this.addEditProductBlock(classes, filter);
  }

  handleChildChange = (event, parent, child) => {
    let newSelected = [...this.state.selectedFoodLabels];
    if (!event) {
      newSelected = [...newSelected, child.value];
    } else {
      newSelected = newSelected.filter((category) => category !== child.value);
      if (!parent.children.some((categoryObj) => newSelected.includes(categoryObj.value))) {
        newSelected = newSelected.filter((category) => category !== parent.name);
      }
    }
    this.setState({ selectedFoodLabels: newSelected });
  };

  handleApplyFilter = () => {
    this.handleDialogClose();
    this.setState({ appliedFoodLables: this.state.selectedFoodLabels })
    this.searchProduct();
  };

  handleClearAll = () => {
    this.setState({ selectedFoodLabels: [] });
  };

  handleFoodLabelsFilterDelete = (foodLabel) => {
    let newSelected = [...this.state.selectedFoodLabels];
    newSelected = newSelected.filter((food) => food != foodLabel)
    this.setState({ selectedFoodLabels: newSelected, appliedFoodLables: newSelected })
    this.searchProduct();
  }

   downloadFile = () => {
    const blob = new Blob([this.state.errorCSVFile], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
        const url = URL.createObjectURL(this.state.errorCSVFile);
        link.setAttribute('href', url);
        link.setAttribute('download', 'error_data.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
  }
 modalData = ()=>{
  return(<>
    {this.state.importStatus == 'success' && <>
                  <img src={successTick} />
                  <div style={{ fontFamily: 'Inter', fontWeight: 700, fontSize: '24px', lineHeight: '32px', marginTop: '15px', color: '#0F172A' }}>CSV Import Completed</div>
                  <div style={{ fontFamily: 'Rubik', fontWeight: 500, fontSize: '14px', lineHeight: '24px', color: '#000000', marginTop: '20px' }}><span style={{ color: '#059669' }}>'Success'&nbsp;</span>{this.state.successfulUploads} items uploaded successfully.</div>
                </>
                }
                 {this.state.importStatus == 'partiallySuccess' && <>
                  <img src={warning} />
                  <div style={{ fontFamily: 'Inter', fontWeight: 700, fontSize: '24px', lineHeight: '32px', marginTop: '15px', color: '#0F172A' }}>CSV Import Completed</div>
                  <div style={{ fontFamily: 'Rubik', fontWeight: 500, fontSize: '14px', lineHeight: '24px', color: '#000000', marginTop: '20px' }}><span style={{ color: '#059669' }}>'Success'&nbsp;</span>{this.state.successfulUploads} products uploaded successfully.</div>
                  <div style={{ fontFamily: 'Rubik', fontWeight: 500, fontSize: '14px', lineHeight: '24px', color: '#000000',  }}><span style={{ color: '#DC2626' }}>'Error'&nbsp;</span>{this.state.failedUploads} products can't be uploaded due to formatting error.</div>
                  <div style={{ fontFamily: 'Rubik', fontWeight: 500, fontSize: '14px', lineHeight: '24px', color: '#000000',  }}>Please<a href={this.state.errorCSVFile}> <span  style={{ color: '#8833FF', cursor:'pointer' }}><u>download the CSV</u>&nbsp;</span></a>file to review the error(s) before re-uploading.</div>

                </>
                }
                  {this.state.importStatus == 'failed' && <>
                  <img src={failed} />
                  <div style={{ fontFamily: 'Inter', fontWeight: 700, fontSize: '24px', lineHeight: '32px', marginTop: '15px', color: '#0F172A' }}>CSV Import Failed</div>
                  <div style={{ fontFamily: 'Rubik', fontWeight: 500, fontSize: '14px', lineHeight: '24px', color: '#000000',  }}><span style={{ color: '#DC2626' }}>'Error'&nbsp;</span>We were unable to import any items from the file.</div>
                  <div style={{ fontFamily: 'Rubik', fontWeight: 500, fontSize: '14px', lineHeight: '24px', color: '#000000',  }}>Please <a href={this.state.errorCSVFile}><span  style={{ color: '#8833FF', cursor:'pointer' }}><u>download the CSV</u>&nbsp;</span></a>file to review and identify the issues before re-uploading.</div>

                </>
                }
                 {this.state.importStatus == 'unknownIssue' && <>
                  <img src={failed} />
                  <div style={{ fontFamily: 'Inter', fontWeight: 700, fontSize: '24px', lineHeight: '32px', marginTop: '15px', color: '#0F172A' }}>Please try again</div>
                  <div style={{ fontFamily: 'Rubik', fontWeight: 500, fontSize: '14px', lineHeight: '24px', color: '#000000',  }}>We're experiencing a technical issue processing uploads. Please try again after sometime.</div>

                </>
                }
  </>)
 }
 foodLabelsMessage=()=>{
  return (
    this.state.appliedFoodLables.length > 0 ? "We couldn't find any matches for your filter criteria. Please reset your filters and try the search option." : "Revise search text and try again"
  )
 }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const filter = createFilterOptions();

    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <SubmitButtonPopup
          data-testId="categorySubmit"
          open={this.state.openSubmit}
          close={this.discardBtn}
          submit={() => this.submitRef.current.click()}
        />
        <AlertMessage
          data-testId="alertMessage"
          open={this.state.openAlert}
          success={this.state.alertSuccess}
          message={this.state.alertMessage}
          close={this.handleAlertMessageClose}
        />
        <Scrollbars>
          {
            this.state.loading ?
              <SectionLoader loading={true} /> :
              <>
                {
                  this.state.productsData?.length > 0 ?
                    <>
                      <Grid container item lg={12} md={12} xs={12} className={classes.root}>
                        <Grid item lg={11} md={11} xs={11}>
                          {
                            this.renderScreen(classes, filter)
                          }
                        </Grid>
                      </Grid>
                      {!!this.state.pagination?.total_count && !this.state.isAddEdit && !this.state.noSearchCategories > 0 && <Box marginBottom={4}>
                        <Pagination
                          data-testId="pagination"
                          count={this.state.pagination?.total_count}
                          onChange={this.handlePageChange}
                          pageSize={this.state.pagination?.per_page}
                          itemText="Menu Items"
                          pageNo={this.state.pagination?.current_page || 1}
                        /></Box>}
                    </> :
                    <Grid container item lg={12} md={12} xs={12} className={classes.root}>
                      <Grid item lg={11} md={11} xs={11}>
                        {
                          this.state.isAddEdit
                          ? this.addEditProductBlock(classes, filter)
                          : (
                              <NoItems
                                showAddItemAI={this.state.showAddItemAI}
                                addItem={this.openAddItemsForm}
                                onAddFromLibraryClick={this.navigateToItemsLibrary}
                                onImportMenu={this.handleCsvClick}
                              />
                          )
                        }
                      </Grid>
                    </Grid>
                }
                <ImportCsvUploadDialog
                  onSubmit={this.uploadCsv}
                  open={this.state.dialogCsvOpen}
                  setOpen={this.handleCsvClose}
                  onSampleDownload={this.downloadItemsSampleCsv}
                  onFileSelect={this.handleCsvSelect}
                  selectedCsvFile={this.state.selectedCsvFile}
                  error={this.state.csvFileError}
                  showHint
                  data-testId="csvuploadDialog"
                  isAI={this.state.isCsvAI}
                />
              </>
          }
        </Scrollbars>
        <Dialog onClose={this.handleDialogClose} aria-labelledby="customized-dialog-title"
          open={this.state.isDialogOpen}
          fullWidth
          PaperProps={{
            style: {
              borderRadius: '3px',
              width: '309px',
              border: '0px solid #D0D2DA',
            },
          }}>
          <DialogTitle style={{ padding: "16px 24px 5px 24px" }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span style={{
                flexGrow: 1,
                fontFamily: 'Rubik',
                fontSize: '18px',
                fontWeight: 500,
                lineHeight: '24px',
                letterSpacing: '-0.02em',
                textAlign: 'left',
                color: '#3C3E49',
              }}
                data-testid="filterTestId"
              >
                Filter
              </span>

              <IconButton aria-label="close" style={{ marginRight: "-12px" }} onClick={this.handleDialogClose}>
                <img src={close} alt="close" />
              </IconButton>
            </div>
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingTop: '0.5rem',
              marginTop: '0.5rem',
              borderBottom: '1px solid #ECEDF0',
              paddingBottom: '10px'
            }}>
              <span className={classes.OrderTextStyle} data-testid="appoinmentDataTestID">
                Food Label
              </span>
              {this.state.isFoodLabelOpen ? (
                <img data-testid="AppoinmentCloseArrow" src={chevron_up} style={{ cursor: "pointer" }} alt="up Arrow" onClick={() => this.setIsFoodLableClose()} />
              ) : (
                <img src={chevron_down} style={{ width: '24px', height: '25px', cursor: "pointer" }} alt="pencil Icon" onClick={this.setIsFoodLabelOpen} data-testid="AppoinmentOpenArrow" />
              )}
            </div>
          </DialogTitle>
          <DialogContent style={{ padding: "0px 24px" }}>
            <FormGroup>
              {this.state.isFoodLabelOpen && this.state.foodLablesData.map((parent) => (
                <Grid container key={parent.id}>
                  <Accordion style={{ width: "100%", boxShadow: "none" }} expanded={this.state.expanded == parent.name}>
                    <AccordionSummary data-testId="handleOpenAccordion" expandIcon={<ExpandMoreIcon onClick={() => this.handleOpenAccordion(parent.name)} />} className={classes.foodFilter} style={{ padding: "0px" }}>
                      <Grid item xs={12}>
                        <MenuItem
                          key={parent.id}
                          value={parent.value}
                          style={{ marginLeft: "-25px" }}
                          className={classes.foodLabelMenu}
                          data-testId="handleParentChange"
                          onClick={() => {
                            this.handleParentChange(parent.children.every((children) => this.state.selectedFoodLabels.includes(children.value)), parent)
                          }}
                        >
                          <PurpleCheckbox
                            className={classes.menuItemCheckbox}
                            checked={parent.children.every((children) => this.state.selectedFoodLabels.includes(children.value))}
                            icon={<span className={classes.icon} />}
                            checkedIcon={<span className={classes.checkedIcon} />}
                          />
                          <Grid
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Grid className={classes.menuItemName} style={{ fontSize: "14px" }}>{parent.name}</Grid>
                          </Grid>
                        </MenuItem>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails style={{ padding: "0px" }}>
                      <Grid item xs={12} >
                        {parent.children.map((child) => (
                          <MenuItem
                            key={child.id}
                            value={child.value}
                            data-testId="handleChildChange"
                            onClick={() => this.handleChildChange(this.state.selectedFoodLabels.includes(child.value), parent, child)}
                          >
                            <PurpleCheckbox
                              className={classes.menuItemCheckbox}
                              checked={this.state.selectedFoodLabels.includes(child.value)}
                              icon={<span className={classes.icon} />}
                              checkedIcon={<span className={classes.checkedIcon} />}
                            />
                            <Grid
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Grid
                                style={{
                                  marginRight: "5px",
                                }}
                              >
                                <img
                                  src={child.img}
                                  alt={child.name}
                                  style={{ height: "28px", width: "28px" }}
                                />
                              </Grid>
                              <Grid className={classes.menuItemName} style={{ fontSize: "14px" }}>{child.name}</Grid>
                            </Grid>
                          </MenuItem>
                        ))}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              ))}
            </FormGroup>
          </DialogContent>
          <DialogActions style={{ padding: "0px", marginTop: "10px" }}>
            <Button style={{
              fontFamily: "Inter",
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "18px",
              color: "#3C3E49",
              textTransform: "none"
            }} data-testId="clearall" onClick={this.handleClearAll}>
              Reset all
            </Button>
            <Button style={{
              fontFamily: "Inter",
              fontSize: "12px",
              fontWeight: 700,
              lineHeight: "18px",
              color: "#3C3E49",
              padding: "10px 32px", background: "#00D659", borderRadius: "3px", textTransform: "none"
            }} data-testId="applyfilter" onClick={this.handleApplyFilter}>
              Apply
            </Button>
          </DialogActions>
        </Dialog >
        <Modal open={this.state.importPopup}>
          <HeaderModalBox>
            <TitleBlock style={{ padding: "40px 34px 0px" }}>
              <ModalTitle>
              {this.modalData()}
              </ModalTitle>
              <Close
                src={CloseIconPng}
                onClick={() => {
                  mixPanelEventHit("Click on Cancel Import Menu completed",{buildCardId : this.mixPanelInfo?.buildCardId, emailId : this.mixPanelInfo?.emailId})
                  this.resetProcess(true)
                }}
                style={{ width: 24, height: 24 }}
                data-test-id="iconCloseButtonCompleted"
              />
            </TitleBlock>

            <ModalContentFooter style={{ justifyContent: 'flex-end', marginTop: '40px' }}>
              <div style={{ display: 'flex' }}>
                <Button
                  variant="text"
                  color="primary"
                  style={{ marginRight: '24px', color: '#3C3E49' }} 
                  data-test-id="cancelButtonCompleted"
                  onClick={() => {
                    mixPanelEventHit("Click on Cancel Import Menu completed",{buildCardId : this.mixPanelInfo?.buildCardId, emailId : this.mixPanelInfo?.emailId})
                    this.resetProcess(true)
                  }}>
                    <span style={{ color: '#3C3E49', fontFamily: 'Rubik', fontWeight: 400, fontSize: '12px' }}> Cancel</span>
                  </Button>
                <ModalEditImage data-test-id="TriggerLogoModalNext" style={{ padding: '15px' }}  onClick={this.state.importStatus == 'success' ? this.resetProcess : this.resetProcess2}>{ this.state.importStatus == 'success' ? 'Continue' : 'Retry' }</ModalEditImage>
              </div>
            </ModalContentFooter>
          </HeaderModalBox>
        </Modal >
        <Modal open={this.state.openDeleteDialog}>
          <HeaderDeleteModalBox>
            <TitleDeleteBlock >
              <ModalTitle>
                <div className={classes.innerDeleteDiv}>
                  <img src={deleteItemsIcon} alt="delete-icon" className={classes.deleteBtnSize} />
                  <div className={classes.dialogDeleteMainText} >
                      {configJSON.dialogDeleteText1} “{this.state.selectedItemCount}” {this.state.selectedItemCount == 1 ? "menu item?" : "menu items?"}
                  </div>
                </div>
              </ModalTitle>
            </TitleDeleteBlock>
            <ModalContentFooter style={{ justifyContent: 'flex-end'}}>
              <div style={{ display: 'flex' }}>
                <Button className={classes.cancelBtn} onClick={this.handleDeleteDialogClose}><span >{configJSON.CancelMenuBtn}</span></Button>
                <ModalEditImage className={classes.itemsDeleteBtn} onClick={this.handleDeleteDialog} data-test-id="itemsDeleteBtn">{configJSON.deleteMenuBtn}</ModalEditImage>
              </div>
            </ModalContentFooter>
          </HeaderDeleteModalBox>
        </Modal >
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const useStyles: Record<string, CSSProperties> = {
  root: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#FAFAFA",
  },
  variantTableTitle: {
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "22px",
    letterSpacing: "0em",
    color: "#3C3E49",
  },
  deleteItems: {
    cursor: "pointer",
    color:"#0F172A",
    fontSize:"12px",
    fontWeight:400,
    textDecoration:"underline",
    lineHeight: "20px",
    marginRight: "8px"
  },
  customTooltip: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Inter",
    fontSize: "12px",
    color: "#0F172A",
    fontWeight: 400,
    lineHeight: "18px",
    borderRadius: "3px",
    height: "38px",
    width: "260px",
    padding: "10px, 20px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.06), 0 4px 8px 0 rgba(0, 0, 0, 0.03)",
    gap: "10px",
    margin: "0 1px",
  },
  checkAllCheckbox: {
    "&$checked": {
      color: "#364F6B",
    },
    "&:hover": {
      background: "none",
    },
    "&.Mui-checked": {
      color: "#6200EA",
    },
  },
  tableData: {
    fontFamily: "Rubik",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "16px",
    color: "#3C3E49",
  },
  noCategoriesBox: {
    backgroundColor: "#fff",
    borderRadius: "10px",
    padding: "50px",
    width: "684px",
    height: "309px",
    marginTop: "100px",
  },
  cancelBtn: {
    textTransform: "none",
    textDecoration: "underline",
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "20px",
    color: "#3C3E49",
    marginRight: "22px"
  },
  itemsDeleteBtn: {
      backgroundColor: "#00D659",
      borderRadius: "3px",
      textTransform: "none",
      background: "#FF1744",
      fontFamily: "Rubik",
      fontSize: "16px",
      fontWeight: "500",
      lineHeight: "24px",
      padding: "15px 30px",
      color: "#0F172A",
      width: "148px",
      height: "64px"
  },
  outerDeleteDiv: {
    display: "flex", 
    alignItems: "center", 
    width: "528px", 
    height: "237px", 
    borderRadius: "3px"
  },
  innerDeleteDiv: {
    display: "flex", 
    alignItems: "center", 
    justifyContent: "center", 
    flexDirection: "column"
  },
  dialogDeleteMainText: {
    fontSize: "24px", 
    fontWeight: 700, 
    padding: "30px 0px 0px", 
    fontFamily: "Inter", 
    lineHeight: "32px", 
    color: "#0F172A", 
    textAlign: "center", 
    width: "85%"
  },
  deleteBtnSize: {
    height: "60px", 
    width: "60px"
  },
  categoriesContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    textAlign: "center",
  },
  variantButton: {
    background: "#00D659",
    textTransform: "none",
    boxShadow: "none",
    borderRadius: "3px",
    fontFamily: "Rubik",
    fontSize: "12px",
    fontweight: "700",
    lineHeight: "18px",
    padding: "10px 26px 10px 26px",
    letterSspacing: "0em",
    color: " #3C3E49",
  },
  discardButton: {
    textDecoration: "underline",
    paddingRight: "20px",
    textTransform: "none",
  },
  categoryButton: {
    background: "#00D659",
    textTransform: "none",
    padding: "10px 26px",
    borderRadius: "3px",
    gap: "8px",
    fontFamily: "Rubik",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "24px",
    color: "#3C3E49",
  },
  addMenuItemButton: {
    background: "#00D659",
    textTransform: "none",
    padding: "10px 26px",
    borderRadius: "3px",
    gap: "8px",
    height: "48px",
    width:"145px",
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "20px",
    color: "#3C3E49",
  },
   addMenuItemButtonDisable: {
    background: "#00D659",
    textTransform: "none",
    padding: "10px 26px",
    borderRadius: "3px",
    gap: "8px",
    height: "48px",
    width:"145px",
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "20px",
    color: "#3C3E49",
    boxShadow: "none"
  },
  backArrow: {
    display: "flex",
    alignItems: "center",
    marginTop: "30px",
  },
  titleText: {
    marginBottom: "5px",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: '19px',
    color: "#000",
    fontFamily: "Rubik",
  },
  foodLables: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "2px",
    height: "28px",
    width: "28px",
  },
  bottomSpace: {
    marginBottom: "20px",
  },
  foodLablesCount: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "2px",
    border: "1px solid #E2E8F0",
    borderRadius: "50%",
    height: "28px",
    width: "28px",
  },
  dataText: {
    fontFamily: "Rubik",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "16px",
    color: "#3C3E49",
  },
  selectFoodLable: {
    "& div": {
      fontFamily: "Rubik",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "24px",
      color: "#64748B",
    },
    "& fieldset": {
      top: "0px",
    },
  },
  headerText: {
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "22px",
    color: "#363636",
  },
  menuItemNameSearch: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "22px",
    color: "#334155",
  },
  menuList: {
    "&:hover": {
      background: "#F6F0FF",
    },
    "&.Mui-selected": {
      background: "#F6F0FF",
    },
  },
  menuItemCheckbox: {
    "&:hover": {
      background: "rgba(0, 0, 0, 0.08)",
    },
    "&.Mui-checked": {
      color: "#6200EA",
    },
  },
  countText: {
    fontFamily: "Rubik",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "16px",
  },
  menuItemName: {
    fontFamily: "Rubik",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "19px",
    color: "#646464",
  },
  placeholderText: {
    "& input, & textarea, & div": {
      fontFamily: "Rubik",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "22px",
      color: "#64748B",
    },
    "& fieldset": {
      top: "0px",
    },
  },
  chipText: {
    background: "#F6F0FF",
    "& span": {
      fontFamily: "Rubik",
      fontSize: "12px",
      fontWeight: "400",
      lineHeight: "16px",
      color: "#646464",
    },
  },
  imageText: {
    fontFamily: "Rubik",
    fontWeight: "500",
    fontSize: "16px",
    color: "#676B7E",
  },
  categoryText: {
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "22px",
    paddingBottom: "10px",
    color: "#676B7E",
  },
  switchLabel: {
    "& .MuiTypography-body1": {
      fontFamily: "Rubik",
      fontSize: "12px",
      fontWeight: "400",
      lineHeight: "16px",
      color: "#646464",
    },
  },
  InputStyle: {
    width: "100%",
    "& > div > div": {
      height: "41px",
      borderRadius: "0px 3px 3px 0px",
    },
    "& > div": {
      background: "#FFFFFF",
    },
    "& input": {
      fontFamily: "Rubik",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "22px",
      color: "#AFB2C0",
    },
    "& fieldset": {
      border: "1px solid #D0D2DA",
      top: "0px",
    },
  },
  icon: {
    border: "1px solid #64748B",
    borderRadius: "3px",
    height: "20px",
    width: "20px",
  },
  checkedIcon: {
    backgroundColor: "#6200EA",
    borderRadius: "3px",
    // backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    "&:before": {
      display: "block",
      borderRadius: "3px",
      width: 20,
      height: 20,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
  },
  OrderTextStyle: {
    fontFamily: 'Rubik',
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '20px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: '#3C3E49',
    width: '122px',
    height: '23px',
    top: '0.5px'
  },
  foodFilter: {
    "& > div": {
      margin: "0px !important"
    },
    "& .MuiAccordionSummary-expandIcon": {
      padding: "0px"
    }
  },
  foodLabelMenu: {
    "&:hover": {
      background: "none"
    }
  },
  MenuActionBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: "center",
    gap: '10px',
    marginLeft: 8,
  },
  MenuActionButton: {
    padding: "10px 26px 10px 20px",
    border: "1px solid #CBD5E1",
    borderRadius: 3,
    width: 226,
    "& .Text": {
      fontFamily: "Rubik",
      fontSize: 16,
      fontWeight: 500,
      textAlign: 'start' as const,
      lineHeight: 1.4,
      color: '#3C3E49',
      textTransform: 'initial',
      whiteSpace: 'nowrap',
      paddingBottom: "3px"
    }
  },
  ActionButtonSection: {
    display: "flex",
    flexDirection: "column",
    textAlign: "start"
  },
  ActionButtonMsg: {
    color: "#475569",
    fontSize: "12px",
    fontWeight: 400,
    textTransform: "none"
  },
  packageDetailsGridItem: {
    marginBottom: 16
  },
  packageDetailsTitleText: {
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 700,
    lineHeight: 1.3,
    marginBottom: 16,
    color: "#3C3E49",
  },
  packageDetailsSubTitleText: {
    fontFamily: 'Rubik',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.3,
    marginBottom: 8,
    color: "#000"
  },
  packageDetailTextField: {
    "& input, & div": {
      fontFamily: "Rubik",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "22px",
      color: "#64748B"
    },
    "& fieldset": {
      top: 0,
      borderRadius: 3,
      border: '1px solid #D0D2DA'
    },
  },
  buttonTitle: {
    margin: "0px",
    textTransform: "capitalize",
    fontWeight: 700,
    fontSize: "14px",
    fontFamily: "Inter",
    color:"#000000"
  },
   buttonTitleDisable: {
    margin: "0px",
    fontWeight: 700,
    fontSize: "14px",
    fontFamily: "Inter",
    color:"#000000",
    lineHeight:"22px"
  },
  addItemMenu: {
    width: "100%",
    marginTop: "80px",
    marginLeft: "-38px" 
  },
  menuOptions: {
    cursor:"pointer",
    borderBottom: "1px solid #E2E8F0",
    textAlign: "start",
    display: "flex",
    flexDirection: "column",
    padding: "5px 10px",
    margin: "5px 10px",
  },
  menuOptionsDisable: {
    display: "flex",
    flexDirection: "column",
    alignItems:"center",
    padding: "5px 10px",
    margin: "5px 10px",
  },
  addItemOption:{
    cursor:"pointer",
    textAlign: "start",
    display: "flex",
    flexDirection: "column",
    padding: "5px 10px",
    margin: "5px 10px",
  },
  optionText2:{
    width: "190px",
    color: "#475569",
    margin: "7px 0",
    fontSize:"12px",
    fontFamily:"Inter",
    fontWeight:400,
  },
   optionText1:{
    width: "170px",
    color: "#475569",
    margin: "7px 0",
    fontSize:"12px",
    fontFamily:"Inter",
    fontWeight:400,
  },
   optionText3:{
    width: "180px",
    color: "#475569",
    margin: "7px 0",
    fontSize:"12px",
    fontFamily:"Inter",
    fontWeight:400,
  },
  optionTextDisable:{
    width: "270px",
    color: "#475569",
    margin: "7px 0",
    fontSize:"12px",
    fontFamily:"Inter",
    fontWeight:400,
    textAlign:"center",
    lineHeight:"18px",
  },
  menuHeader:{
    display: "flex",
    marginBottom: "20px",
    alignItems:"center",
    justifyContent:"space-between"
  }
};
const ModalEditImage = styled("button")({
  width: "120px",
  borderRadius: "3px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "rgba(0, 214, 89, 1)",
  border: "none",
  minHeight: "100%",
  cursor: "pointer",
  color: "rgba(60, 62, 73, 1)",
  fontFamily: "Rubik",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 500,
  boxSizing: "border-box",
  transition: "background-color 0.3s ease",
  // "&:hover": {
  //     backgroundColor: "rgba(0, 214, 89, 1)",
  //     color: "rgba(60, 62, 73, 1)",
  // }
});
const CancelButton = styled(Box)({
  padding: "20px 48px",
  borderRadius: "3px",
  border: "none",
  height: "100%",
  cursor: "pointer",
  textAlign: "center",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "20px",
  textDecorationLine: "underline",
  color: "#DC2626"
});
const ModalContentFooter = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  borderTop: "1px solid #E8E8E8",
});

const HeaderModalBox = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#FFF",
  borderRadius: "3",
  width: "684px",
  flexShrink: 0,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  "& .extraClass": {
    margin: 'auto',
    "& .ExtraClassDropContainer": {
      "& .ExtraClassdropMessage": {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '15px',
        "& .ExtraClassdropMessage": {
          fontSize: '20px !important',
          fontWeight: "500 !important",
          color: 'rgba(103, 107, 126, 1) !important',
          lineHeight: '24px !important',
          fontFamily: "Rubik !important"
        },
        "& .ExtraClasssizeMsg": {
          fontSize: '12px !important',
          color: 'rgba(103, 107, 126, 1) !important',
          padding: '0 85px',
          lineHeight: '24px !important',

        },
      }
    }
  },
})

const HeaderDeleteModalBox = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#FFF",
  borderRadius: "3px",
  width: "525px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  "& .extraClass": {
    margin: 'auto',
    "& .ExtraClassDropContainer": {
      "& .ExtraClassdropMessage": {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '15px',
        "& .ExtraClassdropMessage": {
          fontSize: '20px !important',
          fontWeight: "500 !important",
          color: 'rgba(103, 107, 126, 1) !important',
          lineHeight: '24px !important',
          fontFamily: "Rubik !important"
        },
        "& .ExtraClasssizeMsg": {
          fontSize: '12px !important',
          color: 'rgba(103, 107, 126, 1) !important',
          padding: '0 85px',
          lineHeight: '24px !important',

        },
      }
    }
  },
})

const Close = styled("img")({
  "&:hover": {
    cursor: "pointer",
  },
});

const TitleBlock = styled("div")({
  display: "flex",
  padding: 40,
  color: "#3C3E49",
  width: "100%",
  justifyContent: "space-between"
});

const TitleDeleteBlock = styled("div")({
  display: "flex",
  padding: "40px 0 40px 0",
  color: "#3C3E49",
  width: "100%",
  justifyContent: "space-between"
});

const HeaderLogoTitleBox = styled("div")({
  display: "flex",
  padding: '40px 55px',
  color: "#3C3E49",
  width: "100%",
  justifyContent: "space-between"
});

const ModalTitle = styled(Typography)({
  color: "#3C3E49",
  fontSize: "28px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "32px",
  fontStretch: "normal",
  fontFamily: "Rubik"
});

const ModalInnerText = styled(Typography)({
  fontFamily: "Rubik",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "48px",
  color: "#9B9FB1"
});

const TextSelectionPixel = styled(Typography)({
  color: "#676B7E",
  textAlign: "center",
  fontFamily: "Rubik",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "24px",
  padding: "10px 125px",
});

const UploadButton = styled(Button)({
  display: "none",
  padding: "0",
  background: "#00D659",
  borderRadius: 3,
  fontFamily: "Rubik",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "24px",
  color: "#3C3E49",
  textTransform: "none",
  width: "259px",
  alignItems: "center",
  "& div": {
    width: "100%",
    margin: "0px"
  },
  "& div div": {
    border: "none",
    display: "flex",
    padding: "0"
  },
  "& .Component-dropMessage-130": {
    margin: "0"
  }
});

const GridTextCenter = styled(Grid)({
  textAlign: "center"
});

export default withLayout(withStyles(useStyles)(InventoryManagement));
// Customizable Area End
// Customizable Area End
